import { AddUserRequest, UpdateUserRequest } from "../requests/userService";
import {
  methodDelete,
  methodGet,
  methodPost,
  methodPut,
  verticalFetch,
} from "./httpRequests";
import {
  createUserUrl,
  deleteUserUrl,
  getProfileUrl,
  getRoastingByAdminUrl,
  getUsersUrl,
  getUserUrl,
  updateUserUrl,
} from "./url";

export const getUsers = (type: "customers" | "installers" | "roastings") => {
  return verticalFetch(methodGet, getUsersUrl(type));
};

export const createUser = (body: AddUserRequest) => {
  return verticalFetch(methodPost, createUserUrl, JSON.stringify(body));
};

export const getProfile = () => {
  return verticalFetch(methodGet, getProfileUrl);
};

export const getUser = (uid: string) => {
  return verticalFetch(methodGet, getUserUrl(uid));
};

export const updateUser = (uid: string, body: UpdateUserRequest) => {
  return verticalFetch(methodPut, updateUserUrl(uid), JSON.stringify(body));
};

export const getRoastingByAdmin = (uid: string) => {
  return verticalFetch(methodGet, getRoastingByAdminUrl(uid));
};

export const deleteUser = (uid: string) => {
  return verticalFetch(methodDelete, deleteUserUrl(uid));
};
