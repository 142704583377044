import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import { useNavigate, useParams } from "react-router-dom";
import CustomList from "../../custom/CustomList";
import CustomCard from "../../custom/CustomCard";
import CustomTag from "../../custom/CustomTag";
import {
  costAndSettingsUrl,
  deviceListToDashboardUrl,
  mainUrl,
  roles,
} from "../costants";
import { Link } from "react-router-dom";
import CustomLoading from "../../custom/CustomLoading";
import { dashboardUrl } from "../../custom/utils";
import { getDevice } from "../../../api/services/deviceService";
import { Edit } from "@mui/icons-material";
import CustomIconButton from "../../custom/CustomIconButton";
import {
  checkZfsStatus,
  getZfs,
  getZfsFile,
} from "../../../api/services/configuratorService";
import CustomTable from "../../custom/CustomTable";
import DialogThreshold from "./DialogThreshold";
import { GridRenderCellParams } from "@mui/x-data-grid";
import CustomFeedback from "../../custom/CustomFeedback";
import { getLastData } from "../../../api/services/tsservice";

type DashboardProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  role: string;
};

const Dashboard: React.FC<DashboardProps> = ({ setBreadcrumb, role }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Link
        style={{ color: "#ffffff" }}
        key="1"
        to={deviceListToDashboardUrl("all")}
      >
        {t("selectDevice")}
      </Link>,
      <Typography color="#ffffff" key="4">
        {t("dashboard")}
      </Typography>,
    ]);
  }, []);

  const [loadDevice, setLoadDevice] = useState<boolean>(true);
  const [device, setDevice] = useState({
    roasting: "",
    name: "",
    status: "",
    address: "",
    client: "",
    macinatori: "1",
    modello_macchina: "",
    modello_macinatore: "",
  });
  useEffect(() => {
    getDevice(id!).then((res) => {
      if (res && res.installation) {
        setDevice({
          roasting: res.installation?.azienda,
          address:
            res.installation?.installation_info?.address +
            ", " +
            res.installation?.installation_info?.province +
            " " +
            res.installation?.installation_info?.city,
          name: res.installation.name,
          status: res.installation.status,
          client: res.installation?.customer_assigned_name || "",
          macinatori: res.installation?.doppio_macinatore ? "2" : "1",
          modello_macchina: res.installation?.machine_brand || "",
          modello_macinatore: res.installation?.machine_model || "",
        });
      }
      setLoadDevice(false);
    });
  }, []);

  const [loadParams, setLoadParams] = useState<boolean>(true);
  const [net, setNet] = useState<any>({});
  const [params, setParams] = useState<any>({
    costo1: "N/A",
    costo2: "N/A",
    dose1: "N/A",
    dose2: "N/A",
    macchina_mode: "N/A",
    macchina_w_threshold: "N/A",
    macchina_tri_phase: "N/A",
    macina1_mode: "N/A",
    macina1_w_threshold: "N/A",
    macina1_tri_phase: "N/A",
    macina2_mode: "N/A",
    macina2_w_threshold: "N/A",
    macina2_tri_phase: "N/A",
    thresholds: [],
    macinatore1: [],
    macinatore2: [],
    attraversamento: "N/A",
  });

  useEffect(() => {
    const getParams = () => {
      getZfs(id!).then((res) => {
        if (res && res.files) {
          let paramsFileFound = false;
          res.files.forEach((file: any) => {
            if (file.path === "net.json") {
              getZfsFile(id!, file.id).then((resFile) => {
                setNet(resFile);
              });
            }
            if (file.path === "params.json") {
              paramsFileFound = true;
              getZfsFile(id!, file.id).then((resFile) => {
                getLastData(id!, "z", "*").then((res) => {
                  if (
                    res &&
                    res.result &&
                    res.result.values &&
                    res.result.values[0].value
                  ) {
                    const values = res.result.values[0].value;
                    setParams({
                      macchina_tri_phase: values.macchina[0],
                      macchina_mode: values.macchina[1],
                      macchina_w_threshold: values.macchina[2],
                      macina1_tri_phase: values.macina_1[0],
                      macina1_mode: values.macina_1[1],
                      macina1_w_threshold: values.macina_1[2],
                      macina2_tri_phase: values.macina_2[0],
                      macina2_mode: values.macina_2[1],
                      macina2_w_threshold: values.macina_2[2],
                      costo1: resFile.macina_1.costo,
                      costo2: resFile.macina_2.costo,
                      dose1: resFile.macina_1["g/dose"],
                      dose2: resFile.macina_2["g/dose"],
                      thresholds: resFile.macchina.th,
                      macinatore1: resFile.macina_1.th,
                      macinatore2: resFile.macina_2.th,
                      attraversamento: values.bar,
                    });
                  }
                  setLoadParams(false);
                });
              });
            }
          });
          if (!paramsFileFound) {
            setLoadParams(false);
          }
        } else {
          setLoadParams(false);
        }
      });
    };
    getParams();
    setInterval(() => {
      getParams();
    }, 30000);
  }, []);

  const [open, setOpen] = useState<boolean>(false);

  const [runningZfs, setRunningZfs] = useState<boolean>(false);
  useEffect(() => {
    const checkStatus = async () => {
      checkZfsStatus(id!).then((res) => {
        if (res && res.job && res.job.status) {
          if (res.job.status === "pending") {
            setRunningZfs(true);
          } else {
            setRunningZfs(false);
          }
        }
      });
    };
    checkStatus();
    const intervalId = setInterval(checkStatus, 30000);
    return () => clearInterval(intervalId);
  }, []);

  if (loadDevice || loadParams) {
    return <CustomLoading />;
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        style={{ padding: "100px 32px" }}
      >
        <Grid item xs={12}>
          <CustomTitle
            title={t("dashboard")}
            goBack={() => navigate(deviceListToDashboardUrl("all"))}
          />
        </Grid>
        {runningZfs && (
          <Grid item xs={12}>
            <CustomFeedback label={t("workInProgress")} type="warning" />
          </Grid>
        )}
        <Grid item xs={12} md>
          <CustomCard
            header={{
              visible: true,
              title: `${t("registry")}`,
            }}
            content={
              <CustomList
                dense
                listElement={[
                  {
                    primaryText: t("user"),
                    action: device.client,
                  },
                  {
                    primaryText: t("deviceName"),
                    action: device.name,
                  },
                  {
                    primaryText: t("address"),
                    action: device.address,
                  },
                  {
                    primaryText: t("machineModel"),
                    action: device.modello_macchina,
                  },
                  {
                    primaryText: t("grinderModel"),
                    action: device.modello_macinatore,
                  },
                  {
                    primaryText: t("deviceStatus"),
                    action: (
                      <CustomTag
                        label={String(device.status).toLocaleUpperCase()}
                        type="filled"
                        color={
                          String(device.status).toLocaleLowerCase() === "online"
                            ? "green"
                            : "red"
                        }
                      />
                    ),
                  },
                ]}
              />
            }
          />
        </Grid>
        {role !== roles.installer && role !== roles.roasting ? (
          <Grid item xs={12} md>
            <CustomCard
              fullHeight
              header={{
                visible: true,
                title: `${t("workingParameters")}`,
                action: (
                  <>
                    {role === roles.roasting && (
                      <CustomIconButton
                        icon={<Edit />}
                        onClick={() => navigate(costAndSettingsUrl)}
                      />
                    )}
                  </>
                ),
              }}
              content={
                <CustomList
                  listElement={
                    device && device.macinatori && device.macinatori === "2"
                      ? [
                          {
                            primaryText: t("price1"),
                            action: params.costo1 + " €/kg",
                          },
                          {
                            primaryText: t("price2"),
                            action: params.costo2 + " €/kg",
                          },
                          {
                            primaryText: t("gAmount1"),
                            action: params.dose1 + " g",
                          },
                          {
                            primaryText: t("gAmount2"),
                            action: params.dose2 + " g",
                          },
                        ]
                      : [
                          {
                            primaryText: t("price1"),
                            action: params.costo1 + " €/kg",
                          },
                          {
                            primaryText: t("gAmount1"),
                            action: params.dose1 + " g",
                          },
                        ]
                  }
                />
              }
            />
          </Grid>
        ) : (
          <Grid item xs={12} md>
            <CustomCard
              fullHeight
              header={{
                visible: true,
                title: `${t("workingParameters")}`,
                action: (
                  <CustomIconButton
                    icon={<Edit />}
                    onClick={() => setOpen(true)}
                    disabled={runningZfs}
                  />
                ),
              }}
              content={
                <CustomTable
                  columns={[
                    {
                      headerName: `${t("asset")}`,
                      field: "threshold",
                      flex: 2,
                      filterable: false,
                    },
                    {
                      headerName: `${t("triphase")}`,
                      field: "triphase",
                      flex: 2,
                      filterable: false,
                      renderCell: (params: GridRenderCellParams) =>
                        params.row.triphase ? t("yes") : t("no"),
                    },
                    {
                      headerName: `${t("aggregation")}`,
                      field: "aggregation",
                      flex: 2,
                      filterable: false,
                      renderCell: (params: GridRenderCellParams) =>
                        t(params.row.aggregation),
                    },
                    {
                      headerName: `${t("workingThreshold")}`,
                      field: "value",
                      flex: 2,
                      filterable: false,
                    },
                  ]}
                  rows={
                    device && device.macinatori && device.macinatori === "2"
                      ? [
                          {
                            threshold: t("machine"),
                            aggregation: params.macchina_mode,
                            value: params.macchina_w_threshold + " W",
                            triphase: params.macchina_tri_phase,
                          },
                          {
                            threshold: t("grinder") + " 1",
                            aggregation: params.macina1_mode,
                            value: params.macina1_w_threshold + " W",
                            triphase: params.macina1_tri_phase,
                          },
                          {
                            threshold: t("grinder") + " 2",
                            aggregation: params.macina2_mode,
                            value: params.macina2_w_threshold + " W",
                            triphase: params.macina2_tri_phase,
                          },
                        ]
                      : [
                          {
                            threshold: t("machine"),
                            aggregation: params.macchina_mode,
                            value: params.macchina_w_threshold + " W",
                            triphase: params.macchina_tri_phase,
                          },
                          {
                            threshold: t("grinder") + " 1",
                            aggregation: params.macina1_mode,
                            value: params.macina1_w_threshold + " W",
                            triphase: params.macina1_tri_phase,
                          },
                        ]
                  }
                  hideFooter
                />
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <iframe
            id="grafana_iframe"
            title="grafana_iframe"
            className="responsive-iframe"
            src={dashboardUrl(
              role,
              id!,
              i18n.language === "en" ? "en" : "it",
              device.macinatori
            )}
            style={{
              height: "4000px",
              display: "block",
              width: "100%",
            }}
          />
        </Grid>
        {!loadParams && (
          <DialogThreshold
            id={id!}
            params={params}
            net={net}
            open={open}
            onCancel={() => setOpen(false)}
            onConfirm={() => {
              setOpen(false);
              window.location.reload();
            }}
            doubleGrinder={
              device && device.macinatori ? device.macinatori === "2" : false
            }
            runningZfs={runningZfs}
          />
        )}
      </Grid>
    </>
  );
};

export default Dashboard;
