import React, { useCallback, useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { devicesListToUpdateUrl, mainUrl } from "../costants";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import CustomLoading from "../../custom/CustomLoading";
import { useSnackbar } from "notistack";
import { getDevice, updateDevice } from "../../../api/services/deviceService";

type UpdateDeviceProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const UpdateDevice: React.FC<UpdateDeviceProps> = ({ setBreadcrumb }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [values, setValues] = useState<any>({
    installation: "",
    system_number: "",
    system_serial_number: "",
    address: "",
    city: "",
    province: "",
    id_az: "",
    model: "",
    phone_operator: "",
  });

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Link style={{ color: "#ffffff" }} key="2" to={devicesListToUpdateUrl}>
        {t("updateDevice")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {values.installation}
      </Typography>,
    ]);
  }, [values]);

  useEffect(() => {
    getDevice(id!).then((res) => {
      if (res && res.installation) {
        setValues({
          model: res.installation.model,
          phone_operator: res.installation.phone_operator,
          installation: res.installation.name,
          address: res.installation?.installation_info?.address,
          city: res.installation?.installation_info?.city,
          province: res.installation?.installation_info?.province,
          system_number: res.installation.system_number,
          system_serial_number: res.installation.system_serial_number,
        });
      }
      setLoading(false);
    });
  }, []);

  const handleSubmit = useCallback(() => {
    updateDevice({
      id: id!,
      name: values.installation,
      model: values.model,
      phone_operator: values.phone_operator,
      installation_info: {
        city: values.city,
        province: values.province,
        address: values.address,
      },
      sys_num: values.system_number,
      sys_serial_num: values.system_serial_number,
      id_gestionale: values.id_az,
    }).then((res) => {
      if (res && !res.err) {
        enqueueSnackbar(t("updateDeviceSuccess"), {
          variant: "success",
          preventDuplicate: true,
        });
        navigate(-1);
      } else {
        enqueueSnackbar(res?.err?.message || t("updateDeviceErrror"), {
          variant: "error",
          preventDuplicate: true,
        });
      }
    });
  }, [values]);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={values.installation}
          goBack={() => navigate(devicesListToUpdateUrl)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("installationName")}
                  value={values.installation}
                  handleChange={(e) =>
                    setValues({ ...values, installation: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("address")}
                  value={values.address}
                  handleChange={(e) =>
                    setValues({ ...values, address: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("city")}
                  value={values.city}
                  handleChange={(e) =>
                    setValues({ ...values, city: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("province")}
                  value={values.province}
                  handleChange={(e) =>
                    setValues({ ...values, province: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  disabled
                  label={t("systemNumber")}
                  value={values.system_number}
                  handleChange={(e) =>
                    setValues({ ...values, system_number: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("systemSerialNumber")}
                  value={values.system_serial_number}
                  handleChange={(e) =>
                    setValues({
                      ...values,
                      system_serial_number: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  disabled
                  label={t("idGestionale")}
                  value={values.id_az}
                  handleChange={(e) =>
                    setValues({ ...values, id_az: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() =>
                setValues({
                  installation: "",
                  system_number: "",
                  system_serial_number: "",
                  address: "",
                  city: "",
                  province: "",
                  id_az: "",
                  model: "",
                  phone_operator: "",
                })
              }
              confirm
              type="outlined"
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default UpdateDevice;
