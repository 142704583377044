import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { mainUrl } from "../costants";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import CustomTable from "../../custom/CustomTable";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import CustomLoading from "../../custom/CustomLoading";
import { getDevices } from "../../../api/services/deviceService";

type DevicesListProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  link: string | ((id: string) => string);
  role: string;
};

const DevicesList: React.FC<DevicesListProps> = ({
  setBreadcrumb,
  link,
  role,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  document.body.style.backgroundColor = "#ffffff";

  const [loading, setLoading] = useState<boolean>(true);
  const [devices, setDevices] = useState<any[]>([]);

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("updateDevice")}
      </Typography>,
    ]);
  }, []);

  useEffect(() => {
    // Funzione per controllare il localStorage e chiamare l'endpoint
    const checkLocalStorage = () => {
      const tmp = localStorage.getItem("zerynth_deleting_device");
      if (!tmp) {
        clearInterval(interval); // Pulisce l'interval dopo il primo controllo
        getDevices().then((res) => {
          if (res && res.installations) {
            setDevices([...res.installations]);
          }
          setLoading(false);
        });
      }
    };

    // Esegui la funzione di controllo ogni secondo
    const interval = setInterval(checkLocalStorage, 1000);

    // Esegui il controllo iniziale quando il componente viene montato
    checkLocalStorage();

    // Pulisci l'interval quando il componente viene smontato
    return () => {
      clearInterval(interval);
    };
  }, []); // L'array vuoto come dipendenza indica che questo effetto si verifica solo una volta

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("selectDevice")}
          goBack={() => navigate("/" + mainUrl)}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          columns={[
            {
              headerName: `${t("systemNumber")}`,
              field: "system_number",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <Link
                  style={{ color: "black" }}
                  to={typeof link === "string" ? link : link(params.row.id)}
                >
                  {params.row.system_number}
                </Link>
              ),
            },
            {
              headerName: `${t("systemSerialNumber")}`,
              field: "system_serial_number",
              flex: 1,
            },
            {
              headerName: `${t("idGestionale")}`,
              field: "idGestionale",
              flex: 1,
            },
            {
              headerName: `${t("address")}`,
              field: "installation_info",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  {params.row.installation_info.installation_container_name}{" "}
                  {params.row?.installation_info?.address},{" "}
                  {params.row?.installation_info?.city}{" "}
                  {params.row?.installation_info?.province}
                </>
              ),
            },
          ]}
          rows={[...devices]}
        />
      </Grid>
    </Grid>
  );
};

export default DevicesList;
