import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import { useNavigate, useParams } from "react-router-dom";
import { deviceListToDashboardUrl, mainUrl } from "../costants";
import { Link } from "react-router-dom";
import img from "../../../images/image.png";

type DashboardCustomerProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  role: string;
};

const DashboardCustomer: React.FC<DashboardCustomerProps> = ({
  setBreadcrumb,
  role,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Link
        style={{ color: "#ffffff" }}
        key="1"
        to={deviceListToDashboardUrl("all")}
      >
        {t("selectDevice")}
      </Link>,
      <Typography color="#ffffff" key="4">
        {t("dashboardRoasting")}
      </Typography>,
    ]);
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        style={{ padding: "100px 32px" }}
      >
        <Grid item xs={12}>
          <CustomTitle
            title={t("dashboardRoasting")}
            goBack={() => navigate(mainUrl)}
          />
        </Grid>
        <Grid item xs={12}>
          <img src={img} alt="dashboard" style={{ width: "100%" }} />
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardCustomer;
