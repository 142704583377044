import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import { useNavigate } from "react-router-dom";
import { deviceListToDashboardUrl, mainUrl } from "../costants";
import { Link } from "react-router-dom";
import { getUsers } from "../../../api/services/userService";
import CustomLoading from "../../custom/CustomLoading";
import CustomTable from "../../custom/CustomTable";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { getDevices } from "../../../api/services/deviceService";

type DashboardRoastingProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  role: string;
};

const DashboardRoasting: React.FC<DashboardRoastingProps> = ({
  setBreadcrumb,
  role,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="4">
        {t("dashboardRoasting")}
      </Typography>,
    ]);
  }, []);

  const [users, setUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    getUsers("roastings").then((res) => {
      if (res && res.roastings) {
        setUsers(res.roastings);
      }
      setLoading(false);
    });
  }, []);

  const [clients, setClients] = useState<any[]>([]);
  const [loadingClients, setLoadingClients] = useState<boolean>(true);
  useEffect(() => {
    getUsers("customers").then((res) => {
      if (res && res.customers) {
        setClients(res.customers);
      }
      setLoadingClients(false);
    });
  }, []);

  const [loadingDevices, setLoadingDevices] = useState<boolean>(true);
  const [devices, setDevices] = useState<any[]>([]);
  useEffect(() => {
    getDevices().then((res: any) => {
      if (res && res.installations) {
        setDevices([...res.installations]);
      }
      setLoadingDevices(false);
    });
  }, []);

  if (loading || loadingDevices || loadingClients) {
    return <CustomLoading />;
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        style={{ padding: "100px 32px" }}
      >
        <Grid item xs={12}>
          <CustomTitle
            title={t("dashboardRoasting")}
            goBack={() => navigate(mainUrl)}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            columns={[
              {
                headerName: `${t("email")}`,
                field: "email",
                flex: 1,
              },
              { headerName: `${t("name")}`, field: "name", flex: 1 },
              {
                headerName: `${t("onlyAddress")}`,
                field: "user_info.address",
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                  <>
                    {params.row.user_info.address}, {params.row.user_info.city}{" "}
                    {params.row.user_info.province}
                  </>
                ),
              },
              {
                headerName: `${t("zip")}`,
                field: "user_info.zip_code",
                flex: 0.5,
                renderCell: (params: GridRenderCellParams) => (
                  <> {params.row.user_info.zip_code}</>
                ),
              },
              {
                headerName: `${t("phone")}`,
                field: "user_info.phone",
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                  <> {params.row.user_info.phone}</>
                ),
              },
              {
                headerName: `${t("nUsers")}`,
                field: "user_info.nusers",
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                  <>
                    {
                      clients.filter((e) => e.created_by === params.row.uid)
                        .length
                    }
                  </>
                ),
              },
              {
                headerName: `${t("nDevices")}`,
                field: "user_info.ndevices",
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                  <>
                    {devices.filter((e) => e.azienda === params.row.uid).length}
                  </>
                ),
              },
            ]}
            rows={users}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardRoasting;
