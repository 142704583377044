import {
  routes,
  deviceListToDashboardUrl,
  deviceListToDownloadDataUrl,
} from "../pages/costants";

export const adminMenuItems: (t: any) => any[] = (t: any) => [
  {
    key: "roastings",
    label: t("roastings"),
    link: routes.mainPage,
    elements: [
      {
        key: "createRoasting",
        label: t("createRoasting"),
        link: routes.createRoasting,
        elements: [],
      },
      {
        key: "updateRoasting",
        label: t("updateRoasting"),
        link: routes.roastingListToUpdate,
        elements: [],
      },
      {
        key: "dashboardRoasting",
        label: t("dashboardRoasting"),
        link: routes.dashboardRoasting,
        elements: [],
      },
    ],
  },
  {
    key: "subscriptionManagement",
    label: t("subscriptionManagement"),
    link: routes.subscriptionsManagement,
    elements: [],
  },
  {
    key: "alarms",
    label: t("alarms"),
    elements: [],
    link: routes.alarmsPage,
  },
];

export const roastingMenuItems: (t: any) => any[] = (t: any) => [
  {
    key: "dashboard",
    label: t("dashboard"),
    elements: [],
    link: deviceListToDashboardUrl("all"),
  },
  {
    key: "history",
    label: t("history"),
    link: routes.mainPage,
    elements: [
      {
        key: "datas",
        label: t("datas"),
        elements: [],
        link: deviceListToDownloadDataUrl("all"),
      },
      {
        key: "alarms",
        label: t("alarms"),
        elements: [],
        link: routes.alarmsPage,
      },
      {
        key: "report",
        label: t("report"),
        elements: [],
        link: routes.reportsRoastingPage,
      },
    ],
  },
  {
    key: "users",
    label: t("users"),
    link: routes.mainPage,
    elements: [
      {
        key: "createUser",
        label: t("createUser"),
        link: routes.createUsers,
        elements: [],
      },
      {
        key: "updateUser",
        label: t("updateUser"),
        link: routes.usersList,
        elements: [],
      },
    ],
  },
  {
    key: "costsAndSettings",
    label: t("costsAndSettings"),
    link: routes.costAndSettings,
    elements: [],
  },
  {
    key: "updateParameters",
    label: t("updateParameters"),
    link: routes.updateParams,
    elements: [],
  },
  {
    key: "deleteDevice",
    label: t("deleteDevice"),
    link: routes.deleteDevice,
    elements: [],
  },
];

export const installerMenuItems: (t: any) => any[] = (t: any) => [
  {
    key: "dashboard",
    label: t("dashboard"),
    elements: [],
    link: deviceListToDashboardUrl("all"),
  },
  {
    key: "devices",
    label: t("devices"),
    link: routes.mainPage,
    elements: [
      {
        key: "createDevice",
        label: t("createDevice"),
        link: routes.createDevice,
        elements: [],
      },
      {
        key: "deleteDevice",
        label: t("deleteDevice"),
        link: routes.deleteDevice,
        elements: [],
      },
    ],
  },
  {
    key: "costsAndSettings",
    label: t("costsAndSettings"),
    link: routes.costAndSettings,
    elements: [],
  },
  {
    key: "updateParameters",
    label: t("updateParameters"),
    link: routes.updateParams,
    elements: [],
  },
];

export const customerMenuItems: (t: any) => any[] = (t: any) => [
  {
    key: "dashboard",
    label: t("dashboard"),
    elements: [],
    link: deviceListToDashboardUrl("all"),
  },
  {
    key: "history",
    label: t("history"),
    link: routes.mainPage,
    elements: [
      {
        key: "datas",
        label: t("datas"),
        elements: [],
        link: deviceListToDownloadDataUrl("all"),
      },
      {
        key: "alarms",
        label: t("alarms"),
        elements: [],
        link: routes.alarmsPage,
      },
      {
        key: "report",
        label: t("report"),
        elements: [],
        link: routes.reportsClientPage,
      },
    ],
  },
  {
    key: "updateParameters",
    label: t("updateParameters"),
    link: routes.updateParams,
    elements: [],
  },
];
