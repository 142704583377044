import { Route, Routes, useNavigate } from "react-router-dom";
import {
  dashboardPageUrl,
  deviceListToDashboardUrl,
  deviceListToDownloadDataUrl,
  downloadPageUrl,
  roastingListToDashboardUrl,
  roastingListToDownloadDataUrl,
  roles,
  routes,
  updateDeviceUrl,
  updateRoastingUrl,
  updateUserUrl,
  usersListUrl,
} from "./costants";
import Profile from "./profile/Profile";
import { getProfile } from "../../api/services/userService";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import HomePage from "./homePages/HomePage";
import Navbar from "../navbar/Navbar";
import Dashboard from "./dashboard/Dashboard";
import UpdateUser from "./updateUser/UpdateUser";
import Header from "../header/Header";
import RoastingList from "./roastingList/RoastingList";
import Alarm from "./alarms/Alarm";
import DataDownload from "./dataDownload/DataDownload";
import UpdateRoasting from "./updateRoasting/UpdateRoasting";
import CreateDevice from "./createDevice/CreateDevice";
import UpdateDevice from "./updateDevice/UpdateDevice";
import CustomLoading from "../custom/CustomLoading";
import ConfigurationParams from "./configuration/ConfigurationParams";
import Configuration from "./configuration/Configuration";
import CreateUser from "./createUser/CreateUser";
import UsersList from "./usersList/UsersList";
import { t } from "i18next";
import { useEffect, useState } from "react";
import ConfigurationEnd from "./configuration/ConfigurationEnd";
import CreateRoasting from "./createRoasting/CreateRoasting";
import DevicesList from "./devicesList/DevicesList";
import DevicesFromRoastingList from "./devicesFromRoastingList/DevicesFromRoastingList";
import SubscriptionsManagement from "./subscriptionsManagement/SubscriptionsManagement";
import UpdateSubscription from "./updateSubscription/UpdateSubscription";
import CostsAndSettings from "./costsAndSettings/CostsAndSettings";
import UpdateParams from "./updateParams/UpdateParams";
import DeleteDevice from "./deleteDevice/DeleteDevice";
import DashboardRoasting from "./dashboardRoasting/DashboardRoasting";
import DashboardCustomer from "./dashboardRoasting/DashboardCustomer";
import ReportsClient from "./reports/ReportsClient";
import ReportsRoasting from "./reports/ReportsRoasting";
import ConfigurationSlider from "./configuration/ConfigurationSlider";

const MainPage: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [navbarCollapsed, setNavbarCollapsed] = useState<boolean>(true); //booleano per visualizzare o collassare la navbar nel caso di schermi piccoli
  const [breadcrumb, setBreadcrumb] = useState<any[]>([]); //array di oggetti da visualizzare nel breadcrumb
  const [loading, setLoading] = useState<boolean>(true); //booleano per capire quando finisce la verifica del login e quindi caricare la pagina
  const [role, setRole] = useState<string>(""); //stringa che indica il ruolo dell'utente loggato
  const [configuratorRunning, setConfiguratorRunning] =
    useState<boolean>(false); //booleano per capire se il configuratore è in esecuzione

  //verifica che l'utente sia loggato e in base al ruolo gli assegna le giuste pagine da visualizzare
  useEffect(() => {
    getProfile().then((res: any) => {
      if (!res || !res.user || !res.user.roles || res.user.roles.length === 0) {
        navigate("/login");
      } else {
        setRole(res.user.roles[0]);
      }
      setLoading(false);
    });
  }, []);

  const HomeView = () => {
    return <HomePage role={role} setBreadcrumb={setBreadcrumb} />;
  };

  const ProfileView = () => {
    return <Profile role={role} setBreadcrumb={setBreadcrumb} />;
  };

  const RoastingListToDashboardView = () => {
    return (
      <RoastingList
        title="RoastingListToDashboard"
        link={deviceListToDashboardUrl}
        adminFilter={false}
        setBreadcrumb={setBreadcrumb}
      />
    );
  };

  const DeviceListToDashboardView = () => {
    return (
      <DevicesFromRoastingList
        nextLink={dashboardPageUrl}
        previousLink={roastingListToDashboardUrl}
        previousTitle="RoastingListToDashboard"
        setBreadcrumb={setBreadcrumb}
        title="selectDevice"
        role={role}
        synoptic
      />
    );
  };

  const DashboardPageView = () => {
    return <Dashboard role={role} setBreadcrumb={setBreadcrumb} />;
  };

  const DashboardRoastingView = () => {
    return <DashboardRoasting role={role} setBreadcrumb={setBreadcrumb} />;
  };

  const DashboardCustomerView = () => {
    return <DashboardCustomer role={role} setBreadcrumb={setBreadcrumb} />;
  };

  const RoastingListToDownloadDataView = () => {
    return (
      <RoastingList
        title="RoastingListToDownloadData"
        link={deviceListToDownloadDataUrl}
        adminFilter={false}
        setBreadcrumb={setBreadcrumb}
      />
    );
  };

  const DeviceListToDownloadDataView = () => {
    return (
      <DevicesFromRoastingList
        nextLink={downloadPageUrl}
        previousLink={roastingListToDownloadDataUrl}
        previousTitle="DeviceListToDownloadData"
        setBreadcrumb={setBreadcrumb}
        title="selectDevice"
        role={role}
        synoptic={false}
      />
    );
  };

  const DownloadPageView = () => {
    return <DataDownload setBreadcrumb={setBreadcrumb} />;
  };

  const AlarmPageView = () => {
    return <Alarm setBreadcrumb={setBreadcrumb} admin={role === roles.admin} />;
  };

  const ReportsPageView = () => {
    if (role === roles.customer) {
      return <ReportsClient setBreadcrumb={setBreadcrumb} role={role} />;
    }
    if (role === roles.roasting) {
      return <ReportsRoasting setBreadcrumb={setBreadcrumb} role={role} />;
    }
  };

  const CreateRoastingView = () => {
    return <CreateRoasting setBreadcrumb={setBreadcrumb} />;
  };

  const RoastingListToUpdateView = () => {
    return (
      <UsersList
        setBreadcrumb={setBreadcrumb}
        link={updateRoastingUrl}
        title="updateRoasting"
        types={["roastings"]}
      />
    );
  };

  const UpdateRoastingView = () => {
    return <UpdateRoasting setBreadcrumb={setBreadcrumb} />;
  };

  const CreateUsersView = () => {
    return (
      <CreateUser
        roles={[
          { label: `${t("installer")}`, value: roles.installer },
          { label: `${t("customer")}`, value: roles.customer },
        ]}
        setBreadcrumb={setBreadcrumb}
        title="createUser"
      />
    );
  };

  const UsersListView = () => {
    return (
      <UsersList
        setBreadcrumb={setBreadcrumb}
        link={updateUserUrl}
        title="updateUser"
        types={["installers", "customers"]}
      />
    );
  };

  const UpdateUserView = () => {
    return (
      <UpdateUser
        link={usersListUrl}
        setBreadcrumb={setBreadcrumb}
        title="updateUser"
      />
    );
  };

  const UpdateCostsView = () => {
    return <HomePage role={role} setBreadcrumb={setBreadcrumb} />; //TBD
  };

  const CreateDeviceView = () => {
    return <CreateDevice setBreadcrumb={setBreadcrumb} />;
  };

  const DevicesListToUpdateView = () => {
    return (
      <DevicesList
        role={role}
        setBreadcrumb={setBreadcrumb}
        link={updateDeviceUrl}
      />
    );
  };

  const UpdateDeviceView = () => {
    return <UpdateDevice setBreadcrumb={setBreadcrumb} />;
  };

  const UpdateDeviceExpirationView = () => {
    return <HomePage role={role} setBreadcrumb={setBreadcrumb} />; //TBD
  };

  const ConfigurationParamsView = () => {
    return <ConfigurationParams setBreadcrumb={setBreadcrumb} />;
  };

  const ConfigurationSliderView = () => {
    return <ConfigurationSlider setBreadcrumb={setBreadcrumb} />;
  };

  const ConfigurationView = () => {
    return (
      <Configuration
        setBreadcrumb={setBreadcrumb}
        setConfiguratorRunning={setConfiguratorRunning}
      />
    );
  };

  const ConfigurationEndView = () => {
    return <ConfigurationEnd setBreadcrumb={setBreadcrumb} />;
  };

  const SubscriptionsManagementView = () => {
    return <SubscriptionsManagement setBreadcrumb={setBreadcrumb} />;
  };

  const UpdateSubscriptionView = () => {
    return <UpdateSubscription setBreadcrumb={setBreadcrumb} />;
  };

  const CostsAndSettingsView = () => {
    return <CostsAndSettings setBreadcrumb={setBreadcrumb} role={role} />;
  };

  const UpdateParamsView = () => {
    return <UpdateParams setBreadcrumb={setBreadcrumb} role={role} />;
  };

  const DeleteDeviceView = () => {
    return <DeleteDevice setBreadcrumb={setBreadcrumb} />;
  };

  return (
    <>
      <Header
        configuratorRunning={configuratorRunning}
        breadcrumbs={breadcrumb}
        SetNavbarIsCollapsed={setNavbarCollapsed}
        navbar_collapsed={navbarCollapsed}
        isMobile={isMobile}
      />
      <Navbar
        configuratorRunning={configuratorRunning}
        SetNavbarIsCollapsed={setNavbarCollapsed}
        navbar_collapsed={navbarCollapsed}
        isMobile={isMobile}
      />
      {loading ? (
        <CustomLoading />
      ) : (
        <div style={{ marginLeft: isMobile ? "0px" : "230px" }}>
          <Routes>
            <Route path={routes.mainPage} element={HomeView()} />
            <Route path={routes.profile} element={ProfileView()} />
            <Route
              path={routes.roastingListToDashboard}
              element={RoastingListToDashboardView()}
            />
            <Route
              path={routes.deviceListToDashboard}
              element={DeviceListToDashboardView()}
            />
            <Route path={routes.dashboardPage} element={DashboardPageView()} />
            <Route
              path={routes.dashboardRoasting}
              element={DashboardRoastingView()}
            />
            <Route
              path={routes.dashboardRoastingCustomer}
              element={DashboardCustomerView()}
            />
            <Route
              path={routes.roastingListToDownloadData}
              element={RoastingListToDownloadDataView()}
            />
            <Route
              path={routes.deviceListToDownloadData}
              element={DeviceListToDownloadDataView()}
            />
            <Route path={routes.downloadPage} element={DownloadPageView()} />
            <Route path={routes.alarmsPage} element={AlarmPageView()} />
            <Route
              path={routes.reportsRoastingPage}
              element={ReportsPageView()}
            />
            <Route
              path={routes.reportsClientPage}
              element={ReportsPageView()}
            />
            <Route
              path={routes.createRoasting}
              element={CreateRoastingView()}
            />
            <Route
              path={routes.roastingListToUpdate}
              element={RoastingListToUpdateView()}
            />
            <Route
              path={routes.updateRoasting}
              element={UpdateRoastingView()}
            />
            <Route path={routes.createUsers} element={CreateUsersView()} />
            <Route path={routes.usersList} element={UsersListView()} />
            <Route path={routes.updateUser} element={UpdateUserView()} />
            <Route path={routes.updateCosts} element={UpdateCostsView()} />
            <Route path={routes.createDevice} element={CreateDeviceView()} />
            <Route
              path={routes.devicesListToUpdate}
              element={DevicesListToUpdateView()}
            />
            <Route path={routes.updateDevice} element={UpdateDeviceView()} />
            <Route
              path={routes.updateDeviceExpiration}
              element={UpdateDeviceExpirationView()}
            />
            <Route
              path={routes.configurationParams}
              element={ConfigurationParamsView()}
            />
            <Route
              path={routes.configurationSlider}
              element={ConfigurationSliderView()}
            />
            <Route path={routes.configuration} element={ConfigurationView()} />
            <Route
              path={routes.configurationEnd}
              element={ConfigurationEndView()}
            />
            <Route
              path={routes.subscriptionsManagement}
              element={SubscriptionsManagementView()}
            />
            <Route
              path={routes.updateSubscriptions}
              element={UpdateSubscriptionView()}
            />
            <Route
              path={routes.costAndSettings}
              element={CostsAndSettingsView()}
            />
            <Route path={routes.updateParams} element={UpdateParamsView()} />
            <Route path={routes.deleteDevice} element={DeleteDeviceView()} />
          </Routes>
        </div>
      )}
    </>
  );
};

export default MainPage;
