import { Divider } from "@mui/material";
import React from "react";
import CustomText from "./CustomText";

type CustomDividerProps = {
  label?: string;
  textAlign?: "left" | "center" | "right";
  orientation?: "horizontal" | "vertical";
};

const CustomDivider: React.FC<CustomDividerProps> = ({
  label,
  textAlign,
  orientation,
}) => {
  if (orientation === "vertical") {
    if (label) {
      return (
        <Divider
          key={`key-divider`}
          textAlign={textAlign ? textAlign : "center"}
          orientation="vertical"
          flexItem
        >
          <CustomText type="body2" label={label} />
        </Divider>
      );
    } else {
      return <Divider orientation="vertical" flexItem />;
    }
  } else {
    if (label) {
      return (
        <Divider
          key={`key-${label}`}
          textAlign={textAlign ? textAlign : "center"}
        >
          <CustomText type="body2" label={label} />
        </Divider>
      );
    } else {
      return <Divider />;
    }
  }
};

export default CustomDivider;
