import {
  CircularProgress,
  Grid,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import CustomTitle from "../../custom/CustomTitle";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomCard from "../../custom/CustomCard";
import CustomText from "../../custom/CustomText";
import { useCallback, useEffect, useState } from "react";
import { ESPLoader } from "../../../misc/esp-web-flasher";
import { Dangerous, UsbOff } from "@mui/icons-material";
import CustomButton from "../../custom/CustomButton";
import { Link } from "react-router-dom";
import { configurationEndUrl, mainUrl } from "../costants";
import {
  ActiveSim,
  claimDevice,
  commit,
  getFirmwareVersions,
  getIdentityFromDCN,
  mklfs,
  uploadFiles,
} from "../../../api/services/configuratorService";
import { zdmUrl } from "../../../api/services/url";
import {
  binaryFetch,
  firmwareId,
  workspaceId,
} from "../../../api/services/httpRequests";
import {
  prepareConfigJson,
  prepareNetJson,
  prepareNetTestJson,
  prepareParamsJson,
  prepareSensorsJson,
} from "../../custom/utils";
import { deleteDevice } from "../../../api/services/deviceService";

type ConfigurationProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  setConfiguratorRunning: React.Dispatch<React.SetStateAction<boolean>>;
};

const Configuration: React.FC<ConfigurationProps> = ({
  setBreadcrumb,
  setConfiguratorRunning,
}) => {
  const { id } = useParams(); //device-id
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [configuration, setConfiguration] = useState<any>({});
  useEffect(() => {
    setConfiguration(
      JSON.parse(localStorage.getItem("zerynth_idealcup_configuration") || "{}")
    );
    if (!localStorage.getItem("zerynth_idealcup_configuration")) {
      //navigate(configurationParamsUrl(id!));
    }
    return () => localStorage.removeItem("zerynth_idealcup_configuration");
  }, []);

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
    ]);
  }, []);

  const partitions = {
    bootloader: new ArrayBuffer(0), //os/bootloader.bin
    partitions: new ArrayBuffer(0), //os/partitions.bin
    zerynth: new ArrayBuffer(0), //os/zerynth.bin
    firmware: new ArrayBuffer(0), //firmware/firmware.bin
    otalog: new ArrayBuffer(0), //os/otalog.bin
    fs: new ArrayBuffer(0), //resources/fs.bin
  };

  const [devicePort, setDevicePort] = useState<SerialPort | null>(null);
  const [usbDisconnected, setUsbDisconnected] = useState<boolean>(false);
  const [deviceId, setDeviceId] = useState<string>("");
  const [processError, setProcessError] = useState<string>("");
  const [flashPercentage, setFlashPercentage] = useState<number>(0);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [steps, setSteps] = useState<any[]>([
    {
      label: t("checkingUsbConnection"),
      status: "pending",
    },
    {
      label: t("settingFirmwarePreferences"),
      status: "tbd",
    },
    {
      label: t("updatingTheFirmware"),
      status: "tbd",
    },
    {
      label: t("claimingDevice"),
      status: "tbd",
    },
    {
      label: t("creatingDashboard"),
      status: "tbd",
    },
  ]);

  //STEP 1: checkingUsbConnection
  const handleDeviceSelect = () => {
    navigator.serial
      .requestPort({ filters: [{ usbVendorId: 0x10c4, usbProductId: 0xea60 }] })
      .then((port) => {
        console.log("port selected:", port);
        setConfiguratorRunning(true);
        setDevicePort(port);
        const tmp = [...steps];
        tmp[0].status = "success";
        tmp[1].status = "pending";
        setActiveStep((prev) => prev + 1);
        setSteps([...tmp]);
        handleFirmwarePreferences(port);
      })
      .catch((e) => {
        console.log("no serial port selected:", e);
      });
  };

  //STEP 2: updatingTheFirmware
  const openPort = async (port: SerialPort, baudRate: number, retries = 3) => {
    if (retries > 0) {
      await port
        .open({ baudRate: baudRate })
        .then(() => {
          console.log("serial port opened successfully");
          navigator.serial.onconnect = () => {
            console.log("connected");
          };

          navigator.serial.ondisconnect = () => {
            setUsbDisconnected(true);
            console.log("disconnected");
            setSteps([
              {
                label: t("checkingUsbConnection"),
                status: "pending",
              },
              {
                label: t("settingFirmwarePreferences"),
                status: "tbd",
              },
              {
                label: t("updatingTheFirmware"),
                status: "tbd",
              },
              {
                label: t("claimingDevice"),
                status: "tbd",
              },
              {
                label: t("creatingDashboard"),
                status: "tbd",
              },
            ]);
            setActiveStep(0);
            setDevicePort(null);
            console.log("disconnected");
          };
          return true;
        })
        .catch(async (e: any) => {
          console.log("error during port opening:", e);
          console.log("trying to close the port and reopen it again");
          await port.close();
          await openPort(port, baudRate, retries - 1);
          return false;
        });
    } else {
      setProcessError(`${t("failedToOpenPort")}`);
      setConfiguratorRunning(false);
      console.log("retries finished. failed to open the port.");
    }
  };

  const getPartitions = useCallback(async () => {
    try {
      const test = JSON.parse(
        localStorage.getItem("zerynth_idealcup_configuration") || "{}"
      );
      const vers = (await getFirmwareVersions()) as any;
      const version = vers.versions[0].version || "v0.0.0";
      for (const k of Object.keys(partitions)) {
        console.log("Starting fetching firmware", k);
        if (k != "fs") {
          // @ts-ignore
          const fileIndex: number = vers.versions[0].sources.find((s: any) =>
            s.name.includes(k)
          ).src_id;
          console.log("fileIndex", fileIndex);
          // @ts-ignore
          partitions[k] = (await binaryFetch(
            "GET",
            `${zdmUrl}/workspaces/${workspaceId}/firmwares/${firmwareId}/versions/${version}/download?source=${fileIndex}`
          )) as ArrayBuffer;
        }
      }

      console.log("before mklfsService");
      console.log("test", test);
      const resp = await mklfs({
        files: {
          "zfs/net.json": JSON.stringify(prepareNetJson(test.wifi, test.apn)),
          "zfs/params.json": JSON.stringify(
            prepareParamsJson(
              Number(test ? test.price1 : 18),
              Number(test ? test.price2 : 18),
              Number(test ? test.amount1 : 8.5),
              Number(test ? test.amount2 : 8.5),
              test ? test.thresholds : [18000, 22000, 28000, 32000],
              test ? test.macinatore1 : [1000, 3600, 6000],
              test ? test.macinatore2 : [1000, 3600, 6000],
              Number(test ? test.attraversamento : 15),
              Number(test ? test.macchina_w_thresh : 90000),
              Number(test ? test.macina1_w_thresh : 5000),
              Number(test ? test.macina2_w_thresh : 5000)
            )
          ),
          "zfs/sensors.json": JSON.stringify(prepareSensorsJson),
          "zfs/config.json": JSON.stringify(prepareConfigJson),
          "zfs/net_test.json": JSON.stringify(prepareNetTestJson),
        },
      });
      if (!resp) {
        setProcessError(`${t("failedMklfs")}`);
        setConfiguratorRunning(false);
        console.log("failed to mklfs");
        return "";
      } else {
        console.log("RESP", resp);
        partitions["fs"] = resp as ArrayBuffer;
        console.log("after mklfsService");
        //zfs
        setDeviceId(id!);
        const netFile = {
          file: new File(
            [
              new TextEncoder().encode(
                JSON.stringify(prepareNetJson(test.wifi, test.apn))
              ),
            ] as BlobPart[],
            "net.json"
          ),
        };
        console.log("CONFIGURATION", configuration);
        const paramsFile = {
          file: new File(
            [
              new TextEncoder().encode(
                JSON.stringify(
                  prepareParamsJson(
                    Number(test ? test.price1 : 18),
                    Number(test ? test.price2 : 18),
                    Number(test ? test.amount1 : 8.5),
                    Number(test ? test.amount2 : 8.5),
                    test ? test.thresholds : [18000, 22000, 28000, 32000],
                    test ? test.macinatore1 : [1000, 3600, 6000],
                    test ? test.macinatore2 : [1000, 3600, 6000],
                    Number(test ? test.attraversamento : 15),
                    Number(test ? test.macchina_w_thresh : 90000),
                    Number(test ? test.macina1_w_thresh : 5000),
                    Number(test ? test.macina2_w_thresh : 5000)
                  )
                )
              ),
            ] as BlobPart[],
            "params.json"
          ),
        };
        const netTestFile = {
          file: new File(
            [
              new TextEncoder().encode(JSON.stringify(prepareNetTestJson)),
            ] as BlobPart[],
            "net_test.json"
          ),
        };
        const sensorsFile = {
          file: new File(
            [
              new TextEncoder().encode(JSON.stringify(prepareSensorsJson)),
            ] as BlobPart[],
            "sensors.json"
          ),
        };
        const configFile = {
          file: new File(
            [
              new TextEncoder().encode(JSON.stringify(prepareConfigJson)),
            ] as BlobPart[],
            "config.json"
          ),
        };
        await uploadFiles(id!, [
          netFile,
          paramsFile,
          sensorsFile,
          configFile,
          netTestFile,
        ]).then(async (res: any) => {
          if (res && res.files) {
            await commit(id!, true);
          }
        });
        return id!;
      }
    } catch (e) {
      setProcessError(String(e));
      setConfiguratorRunning(false);
      console.log(e);
      devicePort?.close();
    }
  }, [configuration, id]);

  const flash = async (devicePort: any) => {
    console.log("before get partitions");
    const devId = await getPartitions();
    console.log("after get partitions");
    const tmp = [...steps];
    tmp[1].status = "success";
    tmp[2].status = "pending";
    setActiveStep((prev) => prev + 1);
    setSteps([...tmp]);
    try {
      console.log("DP", devicePort);
      if (devicePort) {
        await openPort(devicePort, 115200);

        const loader = new ESPLoader(devicePort, {
          log: (...args) => console.log(...args),
          debug: (...args) => console.log(...args),
          error: (...args) => console.log(...args),
        });
        console.log("loader initializer", loader);
        try {
          await loader.initialize();
        } catch (error) {
          console.log("loader initialize error", error);
        }
        console.log("loader runstub");
        const espStub = await loader.runStub();
        console.log("setbaudrate");
        await espStub.setBaudrate(921600);
        console.log("PARTITIONS", partitions);
        const ps = [
          {
            name: "bootloader",
            data: partitions.bootloader,
            offset: 0x1000,
          },
          {
            name: "partitions",
            data: partitions.partitions,
            offset: 0x9000,
          },
          {
            name: "otalog",
            data: partitions.otalog,
            offset: 0x910000,
          },
          {
            name: "zerynth",
            data: partitions.zerynth,
            offset: 0x10000,
          },
          {
            name: "firmware",
            data: partitions.firmware,
            offset: 0x210000,
          },
          {
            name: "fs",
            data: partitions.fs,
            offset: 0x920000,
          },
        ];

        for (const p of ps) {
          const i = ps.indexOf(p);
          await espStub.flashData(
            p.data,
            (bytesWritten, totalBytes) => {
              setFlashPercentage(
                i * (100 / ps.length) +
                  (100 / ps.length) * (bytesWritten / totalBytes)
              );
            },
            p.offset
          );
        }

        console.log("stub disconnect");
        await espStub.hardReset();
        await espStub.disconnect();
        await devicePort.close();
        console.log("device flashed successfully");
        return devId;
      } else {
        setProcessError(`${t("noDevicePort")}`);
        setConfiguratorRunning(false);
        console.log("NO DEVICE PORT");
      }
    } catch (e) {
      if (devicePort) {
        devicePort?.close();
      }
      setProcessError(t("failedFlashFirmware") + ": " + String(e));
      setConfiguratorRunning(false);
      console.log("failed to flash firmware", e);
      return "";
    }
    return devId;
  };

  const handleFirmwarePreferences = (port: any) => {
    flash(port).then(async (res) => {
      if (res) {
        console.log("device flashed successfully");
        const tmp = [...steps];
        tmp[2].status = "success";
        tmp[3].status = "pending";
        setActiveStep((prev) => prev + 1);
        setSteps([...tmp]);
        await handleClaimingDevice(port, res);
      } else {
        setProcessError(`${t("failedFlashFirmware")}`);
        setConfiguratorRunning(false);
      }
    });
  };

  //STEP 3: claimingDevice
  const handleClaimingDevice = useCallback(
    async (devicePort: SerialPort, devId: string) => {
      console.log("Starting claim procedure. Device Port: ", devicePort);
      await openPort(devicePort, 115200);
      console.log("port opened");
      // Read answer
      const textDecoder = new TextDecoderStream();
      const readableStreamClosed = devicePort.readable.pipeTo(
        textDecoder.writable
      );
      const reader = textDecoder.readable.getReader();
      console.log("reader created");
      let line = "";
      // Ask bundle to device
      const enc = new TextEncoder();
      // eslint-disable-next-line no-constant-condition
      while (true) {
        console.log("reading");
        const { value, done } = await reader.read();
        if (done) {
          reader.releaseLock();
          break;
        }
        const nonce = Math.floor(new Date().getTime() / 1000);
        line += value;
        if (value && value.includes("\n")) {
          console.log(line);
          if (line.includes("pong")) {
            if (devicePort) {
              const writer = devicePort.writable.getWriter();
              const data = enc.encode(`bundle ${nonce}\n`);
              await writer.write(data);
              writer.releaseLock();
            }
          } else if (line.includes(":")) {
            const bundle = line.replaceAll("\n", "").replaceAll("#", "");
            const parts = bundle.split(":");
            try {
              const b1 = window.atob(parts[0]);
              const b1o = JSON.parse(b1);
              // If the first part of the decoded bundle contains the same nonce => this is the bundle
              // Workaround: sometimes the nonce received from the firmware is x-1
              if (b1o.Nonce === `${nonce}` || b1o.Nonce === `${nonce - 1}`) {
                try {
                  console.log("bundle received ", devId);
                  const res = (await getIdentityFromDCN(b1o.DCN)) as any;
                  if (res && res.identity) {
                    if (res.identity.device_id !== devId) {
                      // Device already claimed by another device
                      setProcessError(
                        `${t("deviceAlreadyClaimed")} ${
                          res.identity.device_id
                        }. ${t("shouldUnclaim")}.`
                      );
                      setConfiguratorRunning(false);
                      console.log("device already claimed (another device id)");
                    } else {
                      const tmp = [...steps];
                      tmp[2].status = "success";
                      tmp[3].status = "pending";
                      setActiveStep((prev) => prev + 1);
                      setSteps([...tmp]);
                      console.log("device already claimed (this device id)");
                    }
                    const loader = new ESPLoader(devicePort, {
                      log: (...args) => console.log(...args),
                      debug: (...args) => console.log(...args),
                      error: (...args) => console.log(...args),
                    });
                    const espStub = await loader.runStub();
                    console.log("setbaudrate");
                    await espStub.setBaudrate(460800);
                    console.log("hardreset after claim");
                    await espStub.hardReset();
                    await espStub.disconnect();
                    await reader.cancel();
                    await readableStreamClosed.catch(() => {});

                    const writer = devicePort.writable.getWriter();
                    const data = enc.encode(`exit\n`);
                    await writer.write(data);
                    writer.releaseLock();
                    break;
                  } else {
                    const res = (await claimDevice(
                      devId,
                      //b1o.DCN,
                      bundle
                    )) as any;
                    if (res && res.identity) {
                      const tmp = [...steps];
                      tmp[2].status = "success";
                      tmp[3].status = "pending";
                      setActiveStep((prev) => prev + 1);
                      setSteps([...tmp]);
                      console.log("device claimed successfully");
                      await reader.cancel();
                      await readableStreamClosed.catch(() => {});
                      const writer = devicePort.writable.getWriter();
                      const data = enc.encode(`exit\n`);
                      await writer.write(data);
                      writer.releaseLock();
                      const test = JSON.parse(
                        localStorage.getItem(
                          "zerynth_idealcup_configuration"
                        ) || "{}"
                      );
                      if (test && test.sim) {
                        const resSim = await ActiveSim(devId);
                        if (resSim && resSim.sim) {
                          console.log("Sim claimed successfully");
                          createDashboard(devId);
                          break;
                        } else {
                          setProcessError(
                            t("simActivationFailed") +
                              " " +
                              resSim?.err?.message
                          );
                          setConfiguratorRunning(false);
                          break;
                        }
                      } else {
                        console.log(
                          "Sim not claimed beacasue not present in configuration"
                        );
                        createDashboard(devId);
                        break;
                      }
                    } else {
                      if (
                        res.message &&
                        (res.message.includes("could be counterfeited") ||
                          res.message.includes("is unknown"))
                      ) {
                        const tmp = [...steps];
                        tmp[2].status = "error";
                        setActiveStep((prev) => prev + 1);
                        setSteps([...tmp]);
                        setProcessError("Error: " + res.message);
                        setConfiguratorRunning(false);
                        await reader.cancel();
                        await readableStreamClosed.catch(() => {});
                        const loader = new ESPLoader(devicePort, {
                          log: (...args: any) => console.log(...args),
                          debug: (...args: any) => console.log(...args),
                          error: (...args: any) => console.log(...args),
                        });
                        const espStub = await loader.runStub();
                        console.log("setbaudrate");
                        await espStub.setBaudrate(921600);
                        console.log("hardreset after claim");
                        await espStub.hardReset();
                        await espStub.disconnect();
                        break;
                      } else {
                        // TODO: handle other error responses
                      }
                    }
                  }
                } catch (e) {
                  console.log("catch", e);
                  break;
                } finally {
                  await reader.cancel();
                  await readableStreamClosed.catch(() => {});
                  if (devicePort) {
                    devicePort?.close();
                  }
                }
              }
            } catch {
              console.log("failed to parse bundle");
            }
          } else if (line.includes("Inactivity timeout")) {
            console.log("timeout");
          } else if (line.includes("#")) {
            setTimeout(async () => {
              if (devicePort) {
                const writer = devicePort.writable.getWriter();
                const data = enc.encode(`ping\n`);
                await writer.write(data);
                writer.releaseLock();
              }
            }, 2000);
          }
          line = "";
        }
      }
    },
    [deviceId, steps]
  );

  //STEP 4: creatingDashboard
  const createDashboard = async (devId: string) => {
    const tmp = [...steps];
    tmp[3].status = "success";
    tmp[4].status = "pending";
    setActiveStep((prev) => prev + 1);
    setSteps([...tmp]);

    setTimeout(() => {
      const tmp2 = [...steps];
      tmp2[4].status = "success";
      setSteps([...tmp2]);
      setConfiguratorRunning(false);
      navigate(configurationEndUrl(id!));
    }, 5000);
  };

  useEffect(() => {
    if (processError) {
      deleteDevice(id!);
    }
  }, [processError]);

  useEffect(() => {
    handleDeviceSelect();
  }, []);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={`${t("configurationInProgress")}`} />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            usbDisconnected ? (
              <Grid
                container
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <CustomText label={t("disconnectedUsb")} type="h6" />
                </Grid>
                <Grid item>
                  <UsbOff style={{ fontSize: "128px" }} />
                </Grid>
                <Grid item xs={7}>
                  <CustomButton
                    fullWidth
                    type="contained"
                    label={t("retry")}
                    onClick={() => window.location.reload()}
                  />
                </Grid>
              </Grid>
            ) : processError ? (
              <Grid
                container
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <CustomText label={processError} type="h6" />
                </Grid>
                <Grid item>
                  <Dangerous style={{ fontSize: "128px", color: "red" }} />
                </Grid>
                <Grid item xs={7}>
                  <CustomButton
                    fullWidth
                    type="contained"
                    label={t("retry")}
                    onClick={() => window.location.reload()}
                  />
                </Grid>
              </Grid>
            ) : (
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map(
                  (step: { label: string; status: string }, index: number) => (
                    <Step key={step.label}>
                      <StepLabel>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <CustomText label={step.label} type="h6" />
                          </Grid>
                          {step.status === "pending" && (
                            <Grid item>
                              <CircularProgress size={25} />
                            </Grid>
                          )}
                          <Grid item xs />
                          {step.status === "pending" && index === 0 && (
                            <Grid item>
                              <CustomButton
                                type="contained"
                                label={t("connect")}
                                onClick={() => {
                                  handleDeviceSelect();
                                }}
                              />
                            </Grid>
                          )}
                          {step.status === "pending" && index === 2 && (
                            <Grid item>{flashPercentage.toFixed(2)}%</Grid>
                          )}
                          {step.status === "pending" && index === 5 && (
                            <Grid item>
                              <CustomButton
                                type="contained"
                                label={t("skip")}
                                onClick={() =>
                                  navigate(configurationEndUrl(id!))
                                }
                              />
                            </Grid>
                          )}
                        </Grid>
                      </StepLabel>
                    </Step>
                  )
                )}
              </Stepper>
            )
          }
        />
      </Grid>
    </Grid>
  );
};

export default Configuration;
