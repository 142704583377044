import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import { useNavigate } from "react-router-dom";
import { mainUrl } from "../costants";
import { Link } from "react-router-dom";
import CustomTable from "../../custom/CustomTable";
import { Chart } from "react-google-charts";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { chartColor, formatMonth, formattedMonth } from "../../custom/utils";
import CustomInput from "../../custom/CustomInput";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomLoading from "../../custom/CustomLoading";
import { getRoastingReports } from "../../../api/services/deviceService";

type ReportsRoastingProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  role: string;
};

const ReportsRoasting: React.FC<ReportsRoastingProps> = ({
  setBreadcrumb,
  role,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="4">
        {t("report")}
      </Typography>,
    ]);
  }, []);

  const [reportLoading, setReportLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [reports, setReports] = useState<any[]>([]);
  const [values, setValues] = useState({
    start_date: formattedMonth(new Date()),
    end_date: formattedMonth(new Date()),
  });

  function convertToISODate(dateStr: string) {
    let [month, year]: any = dateStr.split("/");
    return `${year}-${month.padStart(2, "0")}`;
  }

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const handleSubmit = () => {
    setReportLoading(true);
    getRoastingReports(
      convertToISODate(values.start_date),
      convertToISODate(values.end_date)
    ).then((res: any) => {
      if (res && res.reports) {
        setSelectedRows(res.reports.map((_: any, index: any) => index));
        setReports(res.reports);
      } else {
        setReports([]);
      }
      setLoading(false);
      setReportLoading(false);
    });
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const monthMap: any = {
    Gennaio: 0,
    Febbraio: 1,
    Marzo: 2,
    Aprile: 3,
    Maggio: 4,
    Giugno: 5,
    Luglio: 6,
    Agosto: 7,
    Settembre: 8,
    Ottobre: 9,
    Novembre: 10,
    Dicembre: 11,
  };

  const parseDateString = (dateString: string) => {
    const [month, year] = dateString.split(" ");
    return new Date(Number(year), monthMap[month]);
  };

  function formatDate(dateString: string) {
    // Array dei nomi dei mesi in italiano
    const mesi = [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ];

    // Creazione di un oggetto Date usando la stringa della data
    const [year, month] = dateString.split("-");
    const date = new Date(Number(year), Number(month) - 1); // i mesi sono indicizzati da 0 a 11 in JavaScript

    // Ottenere il nome del mese e l'anno
    const nomeMese = mesi[date.getMonth()];
    const anno = date.getFullYear();

    // Restituire la data formattata
    return `${nomeMese} ${anno}`;
  }

  const [filteredData, setFilteredData] = useState<any[]>([]);
  useEffect(() => {
    if (selectedRows.length > 0) {
      const tmp: any[] = [];
      const header: any[] = ["Data"];
      const deviceNameIndexMap: { [key: string]: number } = {};

      // Crea l'header e mappa i device_name agli indici delle colonne
      reports.forEach((report: any) => {
        const deviceName = `${report.device_name}`; /*`${t("idealcup")} ${report.device_name}`;*/
        if (!header.includes(deviceName)) {
          header.push(deviceName);
          deviceNameIndexMap[deviceName] = header.length - 1; // Mappa il device_name alla posizione della colonna
          header.push({ role: "annotation" });
          header.push({ role: "tooltip", type: "string" });
        }
      });
      tmp.push(header);

      // Crea un set di mesi unici dai report selezionati
      const selectedMonths = new Set<string>();
      selectedRows.forEach((selectedIndex: number) => {
        selectedMonths.add(reports[selectedIndex].month);
      });

      // Popola i dati tenendo conto della posizione dei device_name
      selectedMonths.forEach((month) => {
        //const row: any[] = Array(header.length).fill(0); // Inizializza la riga con zeri
        const row: any[] = header.map((col: any) => {
          // Controlla se il ruolo della colonna è "annotation" o "tooltip"
          if (col?.role === "annotation" || col?.role === "tooltip") {
            return undefined; // Usa una stringa vuota per i ruoli "annotation" e "tooltip"
          } else {
            return 0; // Usa 0 per tutte le altre colonne
          }
        });
        row[0] = formatDate(month); // Imposta la data
        reports.forEach((report: any) => {
          if (
            report.month === month &&
            selectedRows.includes(reports.indexOf(report))
          ) {
            const deviceName = `${report.device_name}`; /*`${t("idealcup")} ${report.device_name}`;*/
            const colIndex = deviceNameIndexMap[deviceName];
            row[colIndex] = Number(report.idealcup);
            row[colIndex + 1] = `${(Number(report.idealcup) * 100).toFixed(
              2
            )} %`;
            row[colIndex + 2] = `${(Number(report.idealcup) * 100).toFixed(
              2
            )} %`;
          }
        });
        tmp.push(row);
      });

      // Ordina i dati in ordine cronologico (a partire dalla seconda riga)
      const sortedTmp = [
        tmp[0],
        ...tmp
          .slice(1)
          .sort(
            (a, b) =>
              parseDateString(a[0]).getTime() - parseDateString(b[0]).getTime()
          ),
      ];

      console.log(sortedTmp);
      setFilteredData([...sortedTmp]);
    } else {
      /*
      const tmp: any[] = [["Data", "IdealCup"]];
      reports.forEach((report: any) => {
        tmp.push([report.month, Number(report.idealcup)]);
      });
      setFilteredData([...tmp]);
      */
    }
  }, [reports, selectedRows]);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        style={{ padding: "100px 32px" }}
      >
        <Grid item xs={12}>
          <CustomTitle title={t("report")} goBack={() => navigate(mainUrl)} />
        </Grid>
        <Grid item xs={8}>
          <CustomCard
            actions={[
              <CustomButton
                fullWidth
                type="outlined"
                label={t("reset")}
                onClick={() => {
                  setValues({ start_date: "", end_date: "" });
                }}
                confirm
                confirmQuestion={`${t("question")}`}
              />,
              <CustomButton
                fullWidth
                type="contained"
                label={t("ok")}
                onClick={handleSubmit}
                disabled={reportLoading}
              />,
            ]}
            content={
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    label={t("selectInitialMonth")}
                    value={values.start_date}
                    handleChange={(e: any) => {
                      setValues({ ...values, start_date: e.target.value });
                    }}
                    validator={{
                      error:
                        values.start_date.length !== 7 &&
                        !/^\d{1,2}\/\d{4}$/.test(values.start_date),
                      helperText:
                        "Inserisci un formato valido per il mese e l'anno (MM/YYYY)",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    label={t("selectFinalMonth")}
                    value={values.end_date}
                    handleChange={(e: any) => {
                      setValues({ ...values, end_date: e.target.value });
                    }}
                    validator={{
                      error:
                        values.end_date.length !== 7 &&
                        !/^\d{1,2}\/\d{4}$/.test(values.end_date),
                      helperText:
                        "Inserisci un formato valido per il mese e l'anno (MM/YYYY)",
                    }}
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>
        {reportLoading ? (
          <CustomLoading />
        ) : (
          <Grid item xs={12}>
            <CustomTable
              checkbox={{
                visible: true,
                selectedRows: selectedRows,
                setSelectedRows: setSelectedRows,
              }}
              columns={[
                {
                  headerName: `${t("deviceName")}`,
                  field: "device_name",
                  flex: 1,
                },
                {
                  headerName: `${t("month")}`,
                  field: "month",
                  flex: 1,
                  renderCell: (params: GridRenderCellParams) => {
                    return formatMonth(
                      t,
                      Number(String(params.row.month).split("-")[1])
                    );
                  },
                },
                {
                  headerName: `${t("averageEnergyConsumption")}`,
                  field: "energy_consumption",
                  flex: 1,
                  renderCell: (params: GridRenderCellParams) =>
                    Number(params.row.energy_consumption?.toFixed(2)) + " Wh",
                },
                {
                  headerName: `${t("groundCoffee")}`,
                  field: "grinded_coffees",
                  flex: 1,
                },
                {
                  headerName: `${t("coffeeConsumed")}`,
                  field: "consumed_coffees",
                  flex: 1,
                },
                {
                  headerName: `${t("discardedCoffee")}`,
                  field: "scrapped_coffees",
                  flex: 1,
                },
                {
                  headerName: `${t("productionQuality")}`,
                  field: "quality",
                  flex: 1,
                  renderCell: (params: GridRenderCellParams) =>
                    Number((params?.row?.quality * 100)?.toFixed(2)) + " %",
                },
                {
                  headerName: `${t("coffeeConsumption")}`,
                  field: "coffee_consumption",
                  flex: 1,
                  renderCell: (params: GridRenderCellParams) =>
                    Number(params.row.coffee_consumption?.toFixed(2)) + " Kg",
                },
                {
                  headerName: `${t("idealcup")}`,
                  field: "idealcup",
                  flex: 1,
                  renderCell: (params: GridRenderCellParams) =>
                    Number((params?.row?.idealcup * 100)?.toFixed(2)) + " %",
                },
              ]}
              rows={reports}
            />
          </Grid>
        )}
        {filteredData.length > 0 && !reportLoading && (
          <Grid item xs={12}>
            <Chart
              key={filteredData.length}
              chartType="ColumnChart"
              width="100%"
              height="400px"
              data={filteredData}
              options={{
                title: `${t("idealcup")}`,
                hAxis: { title: "", titleTextStyle: { color: "#333" } },
                vAxis: {
                  format: "# %",
                  ticks: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
                },
                series: {
                  0: { color: chartColor.green },
                  1: { color: chartColor.purple },
                },
                annotations: {
                  alwaysOutside: true, // Le etichette rimangono all'esterno delle colonne
                  textStyle: {
                    fontSize: 12,
                    auraColor: "none", // Rimuove il contorno bianco attorno al testo
                    color: "#000", // Colore del testo (modificalo se necessario)
                  },
                },
              }}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ReportsRoasting;
