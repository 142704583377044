import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { Collapse, Grid, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { configurationSliderUrl, createDeviceUrl, mainUrl } from "../costants";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomText from "../../custom/CustomText";
import { useSnackbar } from "notistack";
import { isValidIPv4, isValidSSID, isValidWPA2 } from "../../custom/utils";
import CustomIconButton from "../../custom/CustomIconButton";
import CustomDivider from "../../custom/CustomDivider";
import { deleteDevice, getDevice } from "../../../api/services/deviceService";
import CustomLoading from "../../custom/CustomLoading";

type ConfigurationParamsProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const ConfigurationParams: React.FC<ConfigurationParamsProps> = ({
  setBreadcrumb,
}) => {
  const { id } = useParams(); //device-id
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="3">
        {t("firmwareParameters")}
      </Typography>,
    ]);
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const [values, setValues] = useState({
    fw: "",
    price1: 18,
    price2: 18,
    amount1: 8.5,
    amount2: 8.5,
    th1: "9000",
    th2: "600",
    ch1_on: "1",
    ch2_on: "1",
    ch3_on: "1",
    apn: "iot.secure",
    speed: "",
    sim: true,
    macchina_w_thresh: 90000,
    macina1_w_thresh: 5000,
    macina2_w_thresh: 5000,
  });

  const [wifi, setWifi] = useState<any>({
    IFCS: [
      {
        ifc_name: "wifi",
        ifc_params: {
          ssid: "assistenza",
          pwd: "12345678",
          dhcp: true,
          ip: "",
          mask: "",
          gateway: "",
          dns: "",
        },
      },
    ],
  });

  const handleSubmit = () => {
    console.log("CONFIGURATION ", { ...values, wifi: wifi });
    localStorage.setItem(
      "zerynth_idealcup_configuration",
      JSON.stringify({ ...values, wifi: wifi })
    );
  };

  const checkFields = () => {
    if (
      !(
        wifi.IFCS[0].ifc_params.ssid &&
        wifi.IFCS[0].ifc_params.pwd &&
        isValidSSID(wifi.IFCS[0].ifc_params.ssid) &&
        isValidWPA2(wifi.IFCS[0].ifc_params.pwd) &&
        (wifi.IFCS[0].ifc_params.dhcp ||
          (wifi.IFCS[0].ifc_params.ip &&
            wifi.IFCS[0].ifc_params.subnetMask &&
            wifi.IFCS[0].ifc_params.gateway &&
            wifi.IFCS[0].ifc_params.dns &&
            isValidIPv4(wifi.IFCS[0].ifc_params.ip) &&
            isValidIPv4(wifi.IFCS[0].ifc_params.subnetMask) &&
            isValidIPv4(wifi.IFCS[0].ifc_params.gateway) &&
            isValidIPv4(wifi.IFCS[0].ifc_params.dns)))
      )
    ) {
      return false;
    }
    return true;
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [installation, setInstallation] = useState<any>({});
  useEffect(() => {
    getDevice(id!).then((res) => {
      if (res && res.installation) {
        setInstallation(res.installation);
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const tmp = localStorage.getItem("zerynth_idealcup_configuration");
      console.log("TMP", tmp);
      if (!tmp) {
        //quando si esce da questa pagina si controlla se è statop salvato il file di configurazione
        // - se non è stato salvato si cancella l'elevator perche vuol dire che stiamo lasciando la pagina senza configurare veramente un device
        // - se è stato salvato vuol dire che si sta per configurare un device e quindi non si cancella
        localStorage.setItem("zerynth_deleting_device", "true");
        await deleteDevice(id!);
        localStorage.removeItem("zerynth_deleting_device");
      }
    };

    return () => {
      fetchData();
    };
  }, [id]);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={`${t("firmwareParameters")}`}
          goBack={() => {
            deleteDevice(id!);
            navigate(createDeviceUrl);
          }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          header={{
            visible: true,
            title: `${t("networkConfiguration")}`,
          }}
          content={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomDivider label={`${t("sim")}`} textAlign="center" />
              </Grid>
              <Grid item xs={12}>
                <Switch
                  checked={values.sim}
                  onChange={() => setValues({ ...values, sim: !values.sim })}
                />
                {values.sim
                  ? " " + t("simInserted")
                  : " " + t("simNotInserted")}
              </Grid>
              <Grid item xs={12}>
                <CustomDivider label={`${t("wifi")}`} textAlign="center" />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("ssid")}
                  value={wifi.IFCS[0].ifc_params.ssid}
                  handleChange={(e) =>
                    setWifi({
                      ...wifi,
                      IFCS: [
                        {
                          ...wifi.IFCS[0],
                          ifc_params: {
                            ...wifi.IFCS[0].ifc_params,
                            ssid: e.target.value,
                          },
                        },
                      ],
                    })
                  }
                  validator={{
                    error:
                      !!wifi.IFCS[0].ifc_params.ssid &&
                      !isValidSSID(wifi.IFCS[0].ifc_params.ssid),
                    helperText: `${t("invalidSsid")}`,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("networkPassword")}
                  value={wifi.IFCS[0].ifc_params.pwd}
                  handleChange={(e) =>
                    setWifi({
                      ...wifi,
                      IFCS: [
                        {
                          ...wifi.IFCS[0],
                          ifc_params: {
                            ...wifi.IFCS[0].ifc_params,
                            pwd: e.target.value,
                          },
                        },
                      ],
                    })
                  }
                  validator={{
                    error:
                      !!wifi.IFCS[0].ifc_params.pwd &&
                      !isValidWPA2(wifi.IFCS[0].ifc_params.pwd),
                    helperText: `${t("invalidPassword")}`,
                  }}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <CustomIconButton
                      type="outlined"
                      icon={
                        showPassword ? (
                          <VisibilityOff style={{ fontSize: "18px" }} />
                        ) : (
                          <Visibility style={{ fontSize: "18px" }} />
                        )
                      }
                      onClick={handleShowPassword}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CustomText label={`${t("ipv4")}`} type="h6" />
              </Grid>
              <Grid item xs={12}>
                <Switch
                  checked={!wifi.IFCS[0].ifc_params.dhcp}
                  onChange={() =>
                    setWifi({
                      ...wifi,
                      IFCS: [
                        {
                          ...wifi.IFCS[0],
                          ifc_params: {
                            ...wifi.IFCS[0].ifc_params,
                            dhcp: !wifi.IFCS[0].ifc_params.dhcp,
                          },
                        },
                      ],
                    })
                  }
                />
                {!wifi.IFCS[0].ifc_params.dhcp
                  ? " " + t("manual")
                  : " " + t("automaticDhcp")}
              </Grid>
              <Grid item>
                <Collapse
                  in={!wifi.IFCS[0].ifc_params.dhcp}
                  timeout="auto"
                  unmountOnExit
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        label={t("ipAddress")}
                        value={wifi.IFCS[0].ifc_params.ip}
                        handleChange={(e) =>
                          setWifi({
                            ...wifi,
                            IFCS: [
                              {
                                ...wifi.IFCS[0],
                                ifc_params: {
                                  ...wifi.IFCS[0].ifc_params,
                                  ip: e.target.value,
                                },
                              },
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!wifi.IFCS[0].ifc_params.ip &&
                            !isValidIPv4(wifi.IFCS[0].ifc_params.ip),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        label={t("subnetMask")}
                        value={wifi.IFCS[0].ifc_params.subnetMask}
                        handleChange={(e) =>
                          setWifi({
                            ...wifi,
                            IFCS: [
                              {
                                ...wifi.IFCS[0],
                                ifc_params: {
                                  ...wifi.IFCS[0].ifc_params,
                                  mask: e.target.value,
                                },
                              },
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!wifi.IFCS[0].ifc_params.subnetMask &&
                            !isValidIPv4(wifi.IFCS[0].ifc_params.subnetMask),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        label={t("gateway")}
                        value={wifi.IFCS[0].ifc_params.gateway}
                        handleChange={(e) =>
                          setWifi({
                            ...wifi,
                            IFCS: [
                              {
                                ...wifi.IFCS[0],
                                ifc_params: {
                                  ...wifi.IFCS[0].ifc_params,
                                  gateway: e.target.value,
                                },
                              },
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!wifi.IFCS[0].ifc_params.gateway &&
                            !isValidIPv4(wifi.IFCS[0].ifc_params.gateway),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        label={t("dns")}
                        value={wifi.IFCS[0].ifc_params.dns}
                        handleChange={(e) =>
                          setWifi({
                            ...wifi,
                            IFCS: [
                              {
                                ...wifi.IFCS[0],
                                ifc_params: {
                                  ...wifi.IFCS[0].ifc_params,
                                  dns: e.target.value,
                                },
                              },
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!wifi.IFCS[0].ifc_params.dns &&
                            !isValidIPv4(wifi.IFCS[0].ifc_params.dns),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          header={{
            visible: true,
            title: `${t("workingParameters")}: ${t("machine")}`,
          }}
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("machineThresholds")}
                  type="number"
                  value={values.macchina_w_thresh}
                  handleChange={(e: any) => {
                    setValues({ ...values, macchina_w_thresh: e.target.value });
                  }}
                  endAdornment="W"
                />
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          header={{
            visible: true,
            title: `${t("workingParameters")}: ${t("grinder")} 1`,
          }}
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("price1")}
                  type="number"
                  value={values.price1}
                  handleChange={(e: any) => {
                    setValues({ ...values, price1: e.target.value });
                  }}
                  endAdornment="€"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("gAmount1")}
                  type="number"
                  value={values.amount1}
                  handleChange={(e: any) => {
                    setValues({ ...values, amount1: e.target.value });
                  }}
                  endAdornment="g/dose"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("grinderThreshold") + " 1"}
                  type="number"
                  value={values.macina1_w_thresh}
                  handleChange={(e: any) => {
                    setValues({ ...values, macina1_w_thresh: e.target.value });
                  }}
                  endAdornment="W"
                />
              </Grid>
            </Grid>
          }
        />
      </Grid>
      {installation && installation.doppio_macinatore && (
        <Grid item xs={12} md={8}>
          <CustomCard
            header={{
              visible: true,
              title: `${t("workingParameters")}: ${t("grinder")} 2`,
            }}
            content={
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    label={t("price2")}
                    type="number"
                    value={values.price2}
                    handleChange={(e: any) => {
                      setValues({ ...values, price2: e.target.value });
                    }}
                    endAdornment="€"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    label={t("gAmount2")}
                    type="number"
                    value={values.amount2}
                    handleChange={(e: any) => {
                      setValues({ ...values, amount2: e.target.value });
                    }}
                    endAdornment="g/dose"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    label={t("grinderThreshold") + " 2"}
                    type="number"
                    value={values.macina2_w_thresh}
                    handleChange={(e: any) => {
                      setValues({
                        ...values,
                        macina2_w_thresh: e.target.value,
                      });
                    }}
                    endAdornment="W"
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>
      )}
      <Grid item container spacing={2} xs={12} md={8}>
        <Grid item xs={12} md>
          <CustomButton
            confirm
            fullWidth
            label={t("cancel")}
            onClick={() => {
              setWifi({
                IFCS: [
                  {
                    ifc_name: "wifi",
                    ifc_params: {
                      ssid: "Default",
                      pwd: "Mapro",
                      dhcp: true,
                      ip: "",
                      mask: "",
                      gateway: "",
                      dns: "",
                    },
                  },
                ],
              });
              setValues({
                fw: "",
                amount1: 7.5,
                amount2: 8.5,
                price1: 18,
                price2: 18,
                th1: "",
                th2: "",
                ch1_on: "",
                ch2_on: "",
                ch3_on: "",
                apn: "",
                speed: "",
                sim: false,
                macchina_w_thresh: 90000,
                macina1_w_thresh: 5000,
                macina2_w_thresh: 5000,
              });
            }}
            type="outlined"
          />
        </Grid>
        <Grid item xs={12} md>
          <CustomButton
            fullWidth
            label={t("ok")}
            onClick={() => {
              if (checkFields()) {
                handleSubmit();
                navigate(configurationSliderUrl(id!));
              } else {
                enqueueSnackbar(t("invalidFields"), {
                  variant: "error",
                });
              }
            }}
            type="contained"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConfigurationParams;
