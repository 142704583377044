import { isDev } from "../../components/pages/costants";

// METODI HTTP
export const methodPut = "PUT";
export const methodGet = "GET";
export const methodPost = "POST";
export const methodDelete = "DELETE";

export const workspaceId = /*isDev ? "wks-6k89mtbypzx6" :*/ "wks-8y39npuemcqq";
export const fleetId = /*isDev ? "flt-6k89mtd4lwnp" : */ "flt-8y39npur3ugz";
export const firmwareId = /* isDev ? "fmw-8vslvvggnw0z" : */ "fmw-8y6aipmbk9hn";
export const apiKey =
  /*isDev
  ? "kFVDU4LIAd.4VqG$YqSNNISgejX177WWWwOOgzOv78J61Ie9wtQgvcMrvR5-6wjai5oAyqAQ5pg"
  : */ "r6i2b40Rcf.cmljUWaFGmiy1hKSSadAhpbvWMUBXF4ojYTV-E6$2Zmjk6yDOdMmJA8IkL7SYQ1c";

// FETCH
export const verticalFetch = (
  method: string,
  url: string,
  param?: string
): Promise<any> => {
  return fetch(url, {
    method: method,
    credentials: "include",
    body: param,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
};

export const ZDMFetch = (
  method: string,
  url: string,
  param?: string,
  auth?: string
): Promise<any> => {
  return fetch(url, {
    method: method,
    body: param,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-KEY": apiKey,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
};

export const binaryFetch = (method: string, url: string, param?: string) => {
  const requestOptions: RequestInit = {
    method: method,
    credentials: "include" as RequestCredentials,
    headers: {
      "X-API-KEY": apiKey,
    },
    body: param,
  };
  const mockRequestOptions: RequestInit = {
    method: method,
    body: param,
    headers: {
      Accept: "application/octet-stream",
      "Content-Type": "application/json",
      "X-API-KEY": apiKey,
    },
  };
  return fetch(url, isDev ? mockRequestOptions : requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.arrayBuffer();
      }
      throw new Error(response.statusText);
    })
    .catch((error) => console.log(error));
};
