import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import { Grid, Switch, Typography } from "@mui/material";
import CustomTable from "../../custom/CustomTable";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { mainUrl, updateSubscriptionsUrl } from "../costants";
import { Link, useNavigate } from "react-router-dom";
import { getDevices } from "../../../api/services/deviceService";
import { updateSubscriptionStatus } from "../../../api/services/subscriptionService";
import CustomLoading from "../../custom/CustomLoading";

type SubscriptionsManagementProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const SubscriptionsManagement: React.FC<SubscriptionsManagementProps> = ({
  setBreadcrumb,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumb([
      <Link key="1" to={mainUrl} style={{ color: "#ffffff" }}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("subscriptionManagement")}
      </Typography>,
    ]);
  }, []);

  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  useEffect(() => {
    getDevices().then((res: any) => {
      if (res && res.installations) {
        setSubscriptions(res.installations);
      }
      setLoading(false);
    });
  }, [reload]);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("subscriptionManagement")}
          goBack={() => navigate("/" + mainUrl)}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          columns={[
            {
              headerName: `${t("pointOfSale")}`,
              field: "name",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => {
                if (params.row.expiration !== "---") {
                  return (
                    <Link
                      to={updateSubscriptionsUrl(params.row.id)}
                      style={{ color: "black" }}
                    >
                      {params.row.name}
                    </Link>
                  );
                } else {
                  return <>{params.row.name}</>;
                }
              },
            },
            {
              headerName: `${t("finalUser")}`,
              field: "customer_assigned_name",
              flex: 2,
            },
            {
              headerName: `${t("roasting")}`,
              field: "azienda_name",
              flex: 1,
            },
            {
              headerName: `${t("addressPointOfSale")}`,
              field: "installation_info",
              flex: 2,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  {params.row?.installation_info?.address},{" "}
                  {params.row?.installation_info?.city}{" "}
                  {params.row?.installation_info?.province}
                </>
              ),
            },
            {
              headerName: `${t("expiration")}`,
              field: "subscription_expiration",
              flex: 1,
              renderCell: (params: GridRenderCellParams) =>
                new Date(
                  params.row.subscription_expiration
                ).toLocaleDateString(),
            },
            {
              headerName: `${t("status")}`,
              field: "status",
              flex: 1,
              filterable: false,
              renderCell: (params: GridRenderCellParams) => {
                return (
                  <Switch
                    checked={params.row.subscription_status === "active"}
                    onClick={() => {
                      updateSubscriptionStatus(
                        params.row.id,
                        params.row.subscription_status === "active"
                          ? "disabled"
                          : "active"
                      ).then((res: any) => {
                        console.log("RES", res);
                        setReload(!reload);
                      });
                    }}
                  />
                );
              },
            },
          ]}
          rows={subscriptions}
        />
      </Grid>
    </Grid>
  );
};

export default SubscriptionsManagement;
