import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { mainUrl, roastingListToUpdateUrl } from "../costants";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import CustomList from "../../custom/CustomList";
import { updateSubscriptionExpiration } from "../../../api/services/subscriptionService";
import { useSnackbar } from "notistack";
import { getDevice } from "../../../api/services/deviceService";
import CustomLoading from "../../custom/CustomLoading";

type UpdateSubscriptionProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const UpdateSubscription: React.FC<UpdateSubscriptionProps> = ({
  setBreadcrumb,
}) => {
  const { id } = useParams(); //device id
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(true);
  const [device, setDevice] = useState<any>({});
  const [values, setValues] = useState({
    expiration: "",
  });

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Link style={{ color: "#ffffff" }} key="2" to={roastingListToUpdateUrl}>
        {t("subscriptionManagement")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {device?.name || ""}
      </Typography>,
    ]);
  }, [device]);

  useEffect(() => {
    getDevice(id!).then((res) => {
      if (res && res.installation) {
        setDevice(res.installation);
        setValues({
          expiration: new Date(res.installation?.subscription_expiration)
            .toISOString()
            .split("T")[0],
        });
      }
      setLoading(false);
    });
  }, []);

  const handleSubmit = () => {
    updateSubscriptionExpiration(
      id!,
      new Date(values.expiration).toISOString()
    ).then((res) => {
      if (res && !res.err) {
        enqueueSnackbar(t("expirationUpdatedSuccess"), { variant: "success" });
        navigate(-1);
      } else {
        enqueueSnackbar(t("expirationUpdatedError") + " " + res?.err?.message, {
          variant: "error",
        });
      }
    });
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={device?.name || ""} goBack={() => navigate(-1)} />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <CustomList
              listElement={[
                {
                  primaryText: t("user"),
                  action: device?.customer_assigned_name,
                },
                {
                  primaryText: t("deviceName"),
                  action: device?.name,
                },
                {
                  primaryText: `${t("address")}`,
                  action:
                    device?.installation_info?.address +
                    ", " +
                    device?.installation_info?.city +
                    " " +
                    device?.installation_info?.province,
                },
                {
                  primaryText: `${t("user")}`,
                  action: device?.customer_assigned_name || "N/A",
                },
              ]}
            />
          }
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomInput
                  type="date"
                  label={t("expiration")}
                  value={values.expiration}
                  handleChange={(e) => {
                    setValues({ ...values, expiration: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() =>
                setValues({
                  expiration: "",
                })
              }
              type="outlined"
              confirm
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default UpdateSubscription;
