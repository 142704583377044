import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import CustomTable from "../../custom/CustomTable";
import { Grid, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { mainUrl, roles } from "../costants";
import { deleteUser, getUsers } from "../../../api/services/userService";
import CustomLoading from "../../custom/CustomLoading";
import CustomTag from "../../custom/CustomTag";
import CustomIconButton from "../../custom/CustomIconButton";
import { Delete } from "@mui/icons-material";
import { useSnackbar } from "notistack";

type UsersListProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
  link: string | ((id: string) => string);
  types: ("customers" | "installers" | "roastings")[];
};

const UsersList: React.FC<UsersListProps> = ({
  setBreadcrumb,
  title,
  link,
  types,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(true);
  const [users, setUsers] = useState<any[]>([]);
  useEffect(() => {
    const promises = types.map(async (type) => {
      const res = await getUsers(type);
      return res && res[type]?.length > 0 ? res[type] : [];
    });
    Promise.all(promises).then((results) => {
      const tmp = results.reduce((acc, result) => [...acc, ...result], []);
      setUsers(tmp);
      setLoading(false);
    });
  }, [reload]);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t(title)} goBack={() => navigate(mainUrl)} />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          columns={[
            {
              headerName: `${t("email")}`,
              field: "email",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <Link
                  style={{ color: "black" }}
                  to={typeof link === "string" ? link : link(params.row.uid)}
                >
                  {params.row.email}
                </Link>
              ),
            },
            { headerName: `${t("name")}`, field: "name", flex: 1 },
            {
              headerName: `${t("role")}`,
              field: "roles",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  {params.row.roles && params.row.roles.length > 0 ? (
                    <CustomTag
                      label={`${t(params.row.roles[0])}`}
                      type="outlined"
                    />
                  ) : (
                    "N/A"
                  )}
                </>
              ),
            },
            {
              headerName: `${t("onlyAddress")}`,
              field: "user_info.address",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  {params.row.user_info.address}, {params.row.user_info.city}{" "}
                  {params.row.user_info.province}
                </>
              ),
            },
            {
              headerName: `${t("zip")}`,
              field: "user_info.zip_code",
              flex: 0.5,
              renderCell: (params: GridRenderCellParams) => (
                <> {params.row.user_info.zip_code}</>
              ),
            },
            {
              headerName: `${t("phone")}`,
              field: "user_info.phone",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <> {params.row.user_info.phone}</>
              ),
            },
            {
              headerName: ``,
              field: "uid",
              flex: 0.5,
              renderCell: (params: GridRenderCellParams) => {
                return (
                  <CustomIconButton
                    confirm
                    type="outlined"
                    confirmQuestion={
                      params.row.roles[0] === roles.roasting
                        ? `${t("deleteRoastingConfirm")}`
                        : params.row.roles[0] === roles.installer
                        ? `${t("deleteInstallerConfirm")}`
                        : `${t("deleteUserConfirm")}`
                    }
                    icon={<Delete />}
                    onClick={() => {
                      deleteUser(params.row.uid).then((res: any) => {
                        if (res && !res.err) {
                          enqueueSnackbar(t("deleteSuccess"), {
                            variant: "success",
                          });
                          setReload(!reload);
                        } else {
                          enqueueSnackbar(
                            `${t("deleteError")} ${res?.err?.message}`,
                            {
                              variant: "error",
                            }
                          );
                        }
                      });
                    }}
                  />
                );
              },
            },
          ]}
          rows={users}
        />
      </Grid>
    </Grid>
  );
};

export default UsersList;
