import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import CustomTable from "../../custom/CustomTable";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { mainUrl, roles } from "../costants";
import CustomLoading from "../../custom/CustomLoading";
import {
  getDevices,
  getRoastingDevices,
} from "../../../api/services/deviceService";
import Chart from "react-google-charts";
import CustomText from "../../custom/CustomText";
import { chartColor, darkColor, lightColor } from "../../custom/utils";
import CustomTag from "../../custom/CustomTag";
import { Warning } from "@mui/icons-material";

type DevicesFromRoastingListProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  previousLink: string;
  previousTitle: string;
  nextLink: (id: string) => string;
  title: string;
  role: string;
  synoptic: boolean;
};

const DevicesFromRoastingList: React.FC<DevicesFromRoastingListProps> = ({
  setBreadcrumb,
  previousLink,
  previousTitle,
  nextLink,
  title,
  role,
  synoptic,
}) => {
  const { id } = useParams(); //roasting-id oppure "all"
  const { t } = useTranslation();
  const navigate = useNavigate();

  document.body.style.backgroundColor = "#ffffff";

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="3">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  const [devices, setDevices] = useState<any[]>([]);
  const [details, setDetails] = useState<any>({});
  const [consumptions, setConsumptions] = useState<any[]>([]);

  useEffect(() => {
    if (id === "all") {
      getDevices().then((res: any) => {
        if (res && res.installations) {
          setDevices([...res.installations]);
          setDetails(res.details);
          setConsumptions([...res.consumptions]);
        }
        setLoading(false);
      });
    } else {
      getRoastingDevices(id!).then((res: any) => {
        if (res && res.installations) {
          setDevices([...res.installations]);
        }
        setLoading(false);
      });
    }
  }, []);

  // Formatta i dati per includere le percentuali nelle etichette della legenda
  const formattedData = (data: any[]) => {
    const total = data.slice(1).reduce((sum, item) => sum + item[1], 0);
    if (data.length === 1) return [...data, ["No data", 100]];
    return data.map((item, index) => {
      if (index === 0) return item; // Mantieni l'intestazione originale
      const percentage = ((item[1] / total) * 100).toFixed(2) + "%";
      return [`${item[0]} (${percentage})`, item[1]];
    });
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("selectDevice")}
          goBack={() => navigate(mainUrl)}
        />
      </Grid>
      {role === roles.roasting && synoptic && (
        <Grid
          item
          xs={12}
          container
          spacing={2}
          textAlign="center"
          justifyContent="center"
        >
          <Grid item container xs={12} md justifyContent="center">
            <Grid item xs={12}>
              <CustomText label={t("avgIdealcupDevices")} type="h5" />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ paddingLeft: "25%", paddingRight: "25%" }}
            >
              <Chart
                chartType="Gauge"
                width="100%"
                data={[
                  ["Label", "Value"],
                  ["", Number(details?.average_idealcup?.toFixed(2) || 0)],
                ]}
                options={{
                  redFrom: 0,
                  redTo: 33,
                  redColor: chartColor.error,
                  yellowFrom: 34,
                  yellowTo: 66,
                  yellowColor: chartColor.warning,
                  greenFrom: 67,
                  greenTo: 100,
                  greenColor: chartColor.success,
                }}
                formatters={[
                  {
                    type: "NumberFormat",
                    column: 1,
                    options: {
                      suffix: "%",
                    },
                  },
                ]}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            spacing={2}
            xs={12}
            md
            justifyContent="center"
            alignItems="space-between"
          >
            <Grid item xs={12}>
              <CustomText label={t("groundCoffees")} type="h5" />
            </Grid>
            <Grid item xs={12}>
              <CustomText
                label={`${
                  details?.total_grinded_coffee
                    ? Number((details?.total_grinded_coffee / 1000)?.toFixed(2))
                    : 0
                } kg`}
                type="h2"
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            spacing={2}
            xs={12}
            md
            justifyContent="center"
            alignItems="space-between"
          >
            <Grid item xs={12}>
              <CustomText label={t("groundCoffeeCost")} type="h5" />
            </Grid>
            <Grid item xs={12}>
              <CustomText
                label={`${
                  details?.total_cost
                    ? Number(details?.total_cost?.toFixed(2))
                    : 0
                } €`}
                type="h2"
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            spacing={2}
            justifyContent="space-between"
            xs={12}
            md
          >
            <Grid item xs={12}>
              <CustomText label={t("consumptionDevice")} type="h5" />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ paddingLeft: "5%", paddingRight: "5%" }}
            >
              <Chart
                chartType="PieChart"
                width="100%"
                data={formattedData([
                  ["Device", "Consumo"],
                  ...[
                    ...consumptions.map((item: any) => {
                      return [
                        item.installation_name +
                          ": " +
                          Number((item?.coffee_grinded / 1000).toFixed(2)) +
                          " Kg",
                        Number((item?.coffee_grinded / 1000).toFixed(2)),
                      ];
                    }),
                  ],
                ])}
                options={{
                  pieHole: 0.4,
                  is3D: false,
                  colors:
                    consumptions.length > 0
                      ? [
                          chartColor.purple,
                          chartColor.green,
                          lightColor,
                          darkColor,
                        ]
                      : [darkColor],
                  pieSliceText: "none",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <CustomTable
          onRowClick={(row) => navigate(nextLink(row.id))}
          columns={
            synoptic
              ? [
                  {
                    headerName: `${t("deviceName")}`,
                    field: "name",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params: GridRenderCellParams) => (
                      <Link
                        style={{ color: "black" }}
                        to={nextLink(params.row.id)}
                      >
                        {params.row.name}
                      </Link>
                    ),
                  },
                  {
                    headerName: `${t("finalUser")}`,
                    field: "customer_assigned_name",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                  },
                  {
                    headerName: `${t("idealcup")}`,
                    field: "id",
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params: GridRenderCellParams) => (
                      <>
                        {consumptions
                          .filter(
                            (item) => item.installation_id === params.row.id
                          )
                          .map((item: any) => {
                            return item?.idealcup.toFixed(2) || 0;
                          })}
                        %
                      </>
                    ),
                  },
                  {
                    headerName: `${t("grinder")} 1`,
                    field: "customer_assigned_name1",
                    flex: 2,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params: GridRenderCellParams) => (
                      <>
                        {consumptions
                          .filter(
                            (item) => item.installation_id === params.row.id
                          )
                          .map((item: any) => {
                            if (item?.grinder_status[0]) {
                              return t("working");
                            } else {
                              return t("idle");
                            }
                          })}
                      </>
                    ),
                  },
                  {
                    headerName: `${t("grinder")} 2`,
                    field: "customer_assigned_name2",
                    flex: 2,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params: GridRenderCellParams) => (
                      <>
                        {consumptions
                          .filter(
                            (item) => item.installation_id === params.row.id
                          )
                          .map((item: any) => {
                            if (params.row.doppio_macinatore) {
                              if (item?.grinder_status[1]) {
                                return t("working");
                              } else {
                                return t("idle");
                              }
                            } else {
                              return "N/A";
                            }
                          })}
                      </>
                    ),
                  },
                  {
                    headerName: `${t("machine")}`,
                    field: "customer_assigned_name3",
                    flex: 2,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params: GridRenderCellParams) => (
                      <>
                        {consumptions
                          .filter(
                            (item) => item.installation_id === params.row.id
                          )
                          .map((item: any) => {
                            if (item?.machine_status) {
                              return t("working");
                            } else {
                              return t("idle");
                            }
                          })}
                      </>
                    ),
                  },
                  /*
                  {
                    headerName: `${t("totalDetectionTime")}`,
                    field: "customer_assigned_name4",
                    flex: 2,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params: GridRenderCellParams) => (
                      <>
                        {formatWorkingTime(
                          consumptions
                            .filter(
                              (item) => item.installation_id === params.row.id
                            )
                            .map((item: any) => {
                              return item?.working_time || 0;
                            })[0]
                        )}
                      </>
                    ),
                  },
                  {
                    headerName: `${t("groundCoffees")}`,
                    field: "customer_assigned_name5",
                    flex: 2,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params: GridRenderCellParams) => (
                      <>
                        {consumptions
                          .filter(
                            (item) => item.installation_id === params.row.id
                          )
                          .map((item: any) => {
                            return (
                              (item?.coffee_grinded / 1000)?.toFixed(2) || 0
                            );
                          })}{" "}
                        kg
                      </>
                    ),
                  },
                  */
                  {
                    headerName: `${t("costCoffee")}`,
                    field: "customer_assigned_name6",
                    flex: 2,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params: GridRenderCellParams) => (
                      <>
                        {consumptions
                          .filter(
                            (item) => item.installation_id === params.row.id
                          )
                          .map((item: any) => {
                            return item?.coffee_cost?.toFixed(2) || 0;
                          })}{" "}
                        €
                      </>
                    ),
                  },
                ]
              : [
                  {
                    headerName: `${t("deviceName")}`,
                    flex: 1,
                    field: "name",
                    renderCell: (params: GridRenderCellParams) => (
                      <Link
                        style={{ color: "black" }}
                        to={nextLink(params.row.id)}
                      >
                        {params.row.name}
                      </Link>
                    ),
                  },
                  {
                    headerName: `${t("finalUser")}`,
                    flex: 1,
                    field: "customer_assigned_name",
                  },
                  {
                    headerName: `${t("address")}`,
                    flex: 2,
                    field: "installation_info",
                    renderCell: (params: GridRenderCellParams) => (
                      <>
                        {params.row.installation_info.address},{" "}
                        {params.row.installation_info.city},{" "}
                        {params.row.installation_info.province}
                      </>
                    ),
                  },
                  {
                    headerName: `${t("deviceStatus")}`,
                    flex: 1,
                    field: "status",
                    renderCell: (params: GridRenderCellParams) => (
                      <CustomTag
                        label={String(params.row.status).toLocaleUpperCase()}
                        type="filled"
                        color={
                          String(params.row.status).toLocaleLowerCase() ===
                          "online"
                            ? "green"
                            : "red"
                        }
                      />
                    ),
                  },
                  {
                    headerName: `${t("alarms")}`,
                    flex: 1,
                    field: "current_alarms_present",
                    renderCell: (params: GridRenderCellParams) => {
                      if (params.row.current_alarms_present === "yes") {
                        return <Warning color="error" />;
                      } else {
                        return null;
                      }
                    },
                  },
                ]
          }
          rows={[...devices]}
        />
      </Grid>
    </Grid>
  );
};

export default DevicesFromRoastingList;
