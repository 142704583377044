import { methodPut, verticalFetch } from "./httpRequests";
import {
  updateSubscriptionExpirationUrl,
  updateSubscriptionStatusUrl,
} from "./url";

export const updateSubscriptionStatus = (
  deviceId: string,
  status: "active" | "disabled"
) => {
  return verticalFetch(
    methodPut,
    updateSubscriptionStatusUrl(deviceId),
    JSON.stringify({ status: status })
  );
};

export const updateSubscriptionExpiration = (
  deviceId: string,
  expiration: string //formato di data che finisce con Z: 2025-01-02T08:00:00Z
) => {
  return verticalFetch(
    methodPut,
    updateSubscriptionExpirationUrl(deviceId),
    JSON.stringify({ exp_date: expiration })
  );
};
