import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { resetPassword } from "../../../api/services/loginService";
import { loginPageUrl, resetPasswordPageUrlResult } from "../costants";
import logo from "../../../images/logo.png";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import CustomText from "../../custom/CustomText";

type ResetPanelProps = {};

const ResetPassword: React.FC<ResetPanelProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");

  document.body.style.backgroundColor = "#000000";

  const submit = () => {
    resetPassword({ email: email }).then((res) => {
      if (res && !res.err) {
        navigate(resetPasswordPageUrlResult);
      }
    });
  };

  return (
    <Grid height="100vh" container justifyContent="center" alignItems="center">
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Grid item xs={7}>
          <img
            src={logo}
            alt="logo"
            width="30%"
            style={{ marginLeft: "34%", marginRight: "34%" }}
          />
        </Grid>
        <Grid item xs={11} md={8}>
          <CustomCard
            content={
              <Grid item container flexDirection="column" spacing={2}>
                <Grid item>
                  <CustomText label={t("resetPassword")} type="h3" />
                </Grid>
                <Grid item>
                  <CustomInput
                    label={t("email")}
                    value={email}
                    handleChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
              </Grid>
            }
            actions={[
              <CustomButton
                fullWidth
                type="outlined"
                label={t("goBack")}
                onClick={() => navigate(loginPageUrl)}
              />,
              <CustomButton
                fullWidth
                type="contained"
                label={t("reset")}
                onClick={submit}
              />,
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
