import { useTranslation } from "react-i18next";
import React from "react";
import { Grid, LinearProgress, Typography } from "@mui/material";
import { lightColor } from "./utils";

type CustomLoadingProps = {
  label?: string;
};

const CustomLoading: React.FC<CustomLoadingProps> = ({ label }) => {
  const { t } = useTranslation();

  return (
    <Grid
      key={`key-${label}`}
      container
      spacing={2}
      textAlign="center"
      justifyContent="center"
      style={{ marginTop: "180px" }}
    >
      <Grid item xs={12}>
        <Typography variant="h5">{label ? label : t("loading")}</Typography>
      </Grid>
      <Grid item xs={8}>
        <LinearProgress style={{ color: lightColor }} />
      </Grid>
    </Grid>
  );
};

export default CustomLoading;
