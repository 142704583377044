import React, { useEffect, useState } from "react";
import { getProfile } from "../../../api/services/userService";
import { mainUrl, roles } from "../costants";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomCard from "../../custom/CustomCard";
import CustomList from "../../custom/CustomList";
import CustomTitle from "../../custom/CustomTitle";
import { Link, useNavigate } from "react-router-dom";
import CustomLoading from "../../custom/CustomLoading";
import CustomTag from "../../custom/CustomTag";
import CustomIconButton from "../../custom/CustomIconButton";
import { Edit } from "@mui/icons-material";
import UpdateNotification from "./UpdateNotification";
import CustomTable from "../../custom/CustomTable";
import CustomText from "../../custom/CustomText";
import { getDevices } from "../../../api/services/deviceService";
import { GridRenderCellParams } from "@mui/x-data-grid";
import CustomButton from "../../custom/CustomButton";

type ProfileProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  role: string;
};

const Profile: React.FC<ProfileProps> = ({ setBreadcrumb, role }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("profile")}
      </Typography>,
    ]);
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [profile, setProfile] = useState<any>({});
  useEffect(() => {
    getProfile().then((res: any) => {
      if (res && res.user && res.user.roles && res.user.roles.length > 0) {
        setProfile({
          ...res.user,
        });
      }
      setLoading(false);
    });
  }, []);

  const [loadDev, setLoadDev] = useState<boolean>(true);
  const [devices, setDevices] = useState<any[]>([]);
  const [reload, setReload] = useState<boolean>(false);
  useEffect(() => {
    getDevices().then((res) => {
      if (res && res.installations) {
        setDevices(res.installations);
      }
      setLoadDev(false);
    });
  }, [reload]);

  if (loading || loadDev) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t("profile")} goBack={() => navigate(mainUrl)} />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomCard
          header={{ visible: true, title: `${t("account")}` }}
          content={
            <CustomList
              listElement={[
                {
                  primaryText: t("email"),
                  action: profile?.email || "N/A",
                },
                {
                  primaryText: t("name"),
                  action: profile?.name || "N/A",
                },
                {
                  primaryText: t("role"),
                  action:
                    profile && profile.roles && profile.roles.length > 0 ? (
                      <CustomTag label={profile.roles[0]} type="outlined" />
                    ) : (
                      "N/A"
                    ),
                },
                {
                  primaryText: t("creationDate"),
                  action:
                    profile && profile.created_at
                      ? new Date(profile.created_at).toLocaleString()
                      : "N/A",
                },
              ]}
            />
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomCard
          header={{ visible: true, title: `${t("addressAndContacts")}` }}
          content={
            <CustomList
              listElement={[
                {
                  primaryText: t("onlyAddress"),
                  action: profile?.user_info?.address || "N/A",
                },
                {
                  primaryText: t("city"),
                  action: profile?.user_info?.city || "N/A",
                },
                {
                  primaryText: t("province"),
                  action: profile?.user_info?.province || "N/A",
                },
                {
                  primaryText: t("phone"),
                  action: profile?.user_info?.phone || "N/A",
                },
              ]}
            />
          }
        />
      </Grid>
      {(role === roles.roasting || role === roles.customer) && (
        <Grid item xs={12}>
          <CustomCard
            header={{
              visible: true,
              title: `${t("notificationManagement")}`,
              action: (
                <CustomIconButton
                  type="contained"
                  icon={<Edit />}
                  onClick={() => setOpen(true)}
                />
              ),
            }}
            content={
              <CustomList
                listElement={[
                  {
                    primaryText: t("contactEmailNotification"),
                    action:
                      devices.length > 0
                        ? role === roles.roasting
                          ? devices[0]?.azienda_email || "N/A"
                          : devices[0]?.cliente_email || "N/A"
                        : "N/A",
                  },
                ]}
              />
            }
          />
        </Grid>
      )}
      {role === roles.customer && (
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <CustomText label={`${t("subscriptions")}`} type="h6" />
          </Grid>
          <Grid item xs={12}>
            <CustomTable
              columns={[
                {
                  headerName: `${t("name")}`,
                  field: "name",
                  flex: 1,
                },
                {
                  headerName: `${t("address")}`,
                  field: "installation_info",
                  flex: 2,
                  renderCell: (params: GridRenderCellParams) => (
                    <>
                      {params.row?.installation_info?.address},{" "}
                      {params.row?.installation_info?.city}{" "}
                      {params.row?.installation_info?.province}
                    </>
                  ),
                },
                {
                  headerName: `${t("expiration")}`,
                  field: "subscription_expiration",
                  flex: 1,
                  renderCell: (params: GridRenderCellParams) =>
                    new Date(
                      params.row.subscription_expiration
                    ).toLocaleDateString(),
                },
                {
                  headerName: `${t("status")}`,
                  field: "status",
                  flex: 1,
                  filterable: false,
                  renderCell: (params: GridRenderCellParams) => {
                    if (params.row.subscription_status === "active") {
                      return (
                        <CustomTag
                          label={t("enabled")}
                          type="filled"
                          color="green"
                        />
                      );
                    }
                    if (params.row.subscription_status === "disabled") {
                      return (
                        <CustomTag
                          label={t("disabled")}
                          type="filled"
                          color="red"
                        />
                      );
                    }
                  },
                },
                {
                  headerName: `${t("requests")}`,
                  field: "id",
                  flex: 1,
                  filterable: false,
                  renderCell: (params: GridRenderCellParams) => {
                    if (params.row.subscription_status === "active") {
                      return (
                        <CustomButton
                          label={`${t("requestDeactivation")}`}
                          type="outlined"
                          confirm
                          onClick={() => {
                            console.log("CLICK");
                          }}
                        />
                      );
                    }
                    if (params.row.subscription_status === "disabled") {
                      return (
                        <CustomButton
                          label={`${t("requestActivation")}`}
                          type="contained"
                          confirm
                          onClick={() => {
                            console.log("CLICK");
                          }}
                        />
                      );
                    }
                  },
                },
              ]}
              rows={devices}
            />
          </Grid>
        </Grid>
      )}
      <UpdateNotification
        role={role}
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={() => {
          setReload(!reload);
          setOpen(false);
        }}
      />
    </Grid>
  );
};

export default Profile;
