import {
  CreateDeviceRequest,
  GetExportInfoRequest,
  UpdateDeviceRequest,
  updateAlarmsCalibrationRequest,
} from "../requests/deviceService";
import {
  ZDMFetch,
  methodDelete,
  methodGet,
  methodPost,
  methodPut,
  verticalFetch,
} from "./httpRequests";
import {
  getExportInfoUrl,
  createDeviceUrl,
  deleteDeviceUrl,
  getAlarmsUrl,
  getBoardTypeUrl,
  getContainersUrl,
  getDeviceUrl,
  getDevicesUrl,
  sendJobUrl,
  updateDeviceUrl,
  updateAlarmsCalibrationUrl,
  getDeviceAlarmsUrl,
  getAlarmsCalibrationUrl,
  getRoastingDevicesUrl,
  getClientReportsUrl,
  getRoastingReportsUrl,
} from "./url";

export const createDevice = (body: CreateDeviceRequest) => {
  return verticalFetch(methodPost, createDeviceUrl, JSON.stringify(body));
};

export const getDevices = () => {
  return verticalFetch(methodGet, getDevicesUrl);
};

export const getRoastingDevices = (id: string) => {
  return verticalFetch(methodGet, getRoastingDevicesUrl(id));
};

export const getDevice = (id: string) => {
  return verticalFetch(methodGet, getDeviceUrl(id));
};

export const updateDevice = (r: UpdateDeviceRequest) => {
  return verticalFetch(methodPut, updateDeviceUrl(r.id), JSON.stringify(r));
};

export const deleteDevice = (id: string) => {
  return verticalFetch(
    methodDelete,
    deleteDeviceUrl,
    JSON.stringify({ installation_id: id })
  );
};

export const getBoardType = () => {
  return verticalFetch(methodGet, getBoardTypeUrl);
};

export const getContainers = () => {
  return verticalFetch(methodGet, getContainersUrl);
};

export const sendJob = (id: string, jobName: string, body: any) => {
  return ZDMFetch(
    methodPost,
    sendJobUrl(id, jobName),
    JSON.stringify({ value: body })
  );
};

export const getAlarms = (start: string, end: string) => {
  return verticalFetch(methodGet, getAlarmsUrl(start, end));
};

export const getClientReports = (start: string, end: string) => {
  /*
  return new Promise((resolve, reject) => {
    resolve({
      reports: [
        {
          device_id: "a",
          device_name: "a",
          month: "2024-07-01",
          energy_consumption: 1.334239662532981,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.33,
        },
        {
          device_id: "a",
          device_name: "a",
          month: "2024-06-01",
          energy_consumption: 1.334239662532981,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.1,
        },
        {
          device_id: "a",
          device_name: "a",
          month: "2024-05-01",
          energy_consumption: 3,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.2,
        },
        {
          device_id: "a",
          device_name: "a",
          month: "2024-04-01",
          energy_consumption: 10,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.14,
        },
        {
          device_id: "a",
          device_name: "a",
          month: "2024-03-01",
          energy_consumption: 13,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.1,
        },
        {
          device_id: "b",
          device_name: "b",
          month: "2024-07-01",
          energy_consumption: 1.334239662532981,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.33,
        },
        {
          device_id: "b",
          device_name: "b",
          month: "2024-06-01",
          energy_consumption: 1.334239662532981,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.1,
        },
        {
          device_id: "b",
          device_name: "b",
          month: "2024-05-01",
          energy_consumption: 3,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.2,
        },
        {
          device_id: "b",
          device_name: "b",
          month: "2024-04-01",
          energy_consumption: 10,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.14,
        },
        {
          device_id: "b",
          device_name: "b",
          month: "2024-03-01",
          energy_consumption: 13,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.1,
        },
      ],
    });
  });
  */
  return verticalFetch(methodGet, getClientReportsUrl(start, end));
};

export const getRoastingReports = (start: string, end: string) => {
  /*
  return new Promise((resolve, reject) => {
    resolve({
      reports: [
        {
          device_id: "a",
          device_name: "a",
          month: "2024-07-01",
          energy_consumption: 1.334239662532981,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.33,
        },
        {
          device_id: "a",
          device_name: "a",
          month: "2024-06-01",
          energy_consumption: 1.334239662532981,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.1,
        },
        {
          device_id: "a",
          device_name: "a",
          month: "2024-05-01",
          energy_consumption: 3,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.2,
        },
        {
          device_id: "a",
          device_name: "a",
          month: "2024-04-01",
          energy_consumption: 10,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.14,
        },
        {
          device_id: "a",
          device_name: "a",
          month: "2024-03-01",
          energy_consumption: 13,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.1,
        },
        {
          device_id: "b",
          device_name: "b",
          month: "2024-07-01",
          energy_consumption: 1.334239662532981,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.33,
        },
        {
          device_id: "b",
          device_name: "b",
          month: "2024-06-01",
          energy_consumption: 1.334239662532981,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.1,
        },
        {
          device_id: "b",
          device_name: "b",
          month: "2024-05-01",
          energy_consumption: 3,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.2,
        },
        {
          device_id: "b",
          device_name: "b",
          month: "2024-04-01",
          energy_consumption: 10,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.14,
        },
        {
          device_id: "b",
          device_name: "b",
          month: "2024-03-01",
          energy_consumption: 13,
          coffee_consumption: 0.29300000000000004,
          grinded_coffees: 81,
          consumed_coffees: 2,
          scrapped_coffees: 3,
          quality: 0.024691358024691357,
          idealcup: 0.1,
        },
      ],
    });
  });
  */
  return verticalFetch(methodGet, getRoastingReportsUrl(start, end));
};

export const getDeviceAlarms = (id: string, end: boolean) => {
  return verticalFetch(methodGet, getDeviceAlarmsUrl(id, end));
};

export const updateAlarmsCalibration = (
  req: updateAlarmsCalibrationRequest
) => {
  return verticalFetch(
    methodPut,
    updateAlarmsCalibrationUrl(req.installation_id),
    JSON.stringify(req)
  );
};

export const getAlarmsCalibration = (id: string) => {
  return verticalFetch(methodGet, getAlarmsCalibrationUrl(id));
};

export const getExportInfo = (info: GetExportInfoRequest) => {
  return verticalFetch(methodGet, getExportInfoUrl(info));
};
