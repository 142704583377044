import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import CustomTable from "../../custom/CustomTable";
import { GridRenderCellParams } from "@mui/x-data-grid";
import CustomTag from "../../custom/CustomTag";
import { mainUrl } from "../costants";
import { Link } from "react-router-dom";
import CustomLoading from "../../custom/CustomLoading";
import { Delete } from "@mui/icons-material";
import { deleteDevice, getDevices } from "../../../api/services/deviceService";
import CustomIconButton from "../../custom/CustomIconButton";
import { useSnackbar } from "notistack";

type DeleteDeviceProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const DeleteDevice: React.FC<DeleteDeviceProps> = ({ setBreadcrumb }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  document.body.style.backgroundColor = "#ffffff";

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="3">
        {t("deleteDevice")}
      </Typography>,
    ]);
  }, []);

  const [reload, setReload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [devices, setDevices] = useState<any[]>([]);
  useEffect(() => {
    // Funzione per controllare il localStorage e chiamare l'endpoint
    const checkLocalStorage = () => {
      const tmp = localStorage.getItem("zerynth_deleting_device");
      if (!tmp) {
        clearInterval(interval); // Pulisce l'interval dopo il primo controllo
        getDevices().then((res) => {
          if (res && res.installations) {
            setDevices([...res.installations]);
          }
          setLoading(false);
        });
      }
    };

    // Esegui la funzione di controllo ogni secondo
    const interval = setInterval(checkLocalStorage, 1000);

    // Esegui il controllo iniziale quando il componente viene montato
    checkLocalStorage();

    // Pulisci l'interval quando il componente viene smontato
    return () => {
      clearInterval(interval);
    };
  }, [reload]);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("deleteDevice")}
          goBack={() => navigate(mainUrl)}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          columns={[
            {
              headerName: `${t("deviceName")}`,
              field: "name",
              flex: 1,
            },
            {
              headerName: `${t("user")}`,
              field: "customer_assigned_name",
              flex: 1,
            },
            {
              headerName: `${t("address")}`,
              field: "installation_info",
              flex: 2,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  {params.row.installation_info.address},{" "}
                  {params.row.installation_info.city},{" "}
                  {params.row.installation_info.province}
                </>
              ),
            },
            {
              headerName: `${t("status")}`,
              field: "status",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <CustomTag
                  label={String(params.row.status).toLocaleUpperCase()}
                  type="filled"
                  color={
                    String(params.row.status).toLocaleLowerCase() === "online"
                      ? "green"
                      : "red"
                  }
                />
              ),
            },
            {
              headerName: `${t("delete")}`,
              field: "delete",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <CustomIconButton
                  confirm
                  type="outlined"
                  icon={<Delete />}
                  onClick={() => {
                    deleteDevice(params.row.id).then((res: any) => {
                      if (res && !res.err) {
                        setReload(!reload);
                      } else {
                        enqueueSnackbar(
                          `${t("deleteDeviceError")} ${res?.err?.message}`,
                          {
                            variant: "error",
                          }
                        );
                      }
                    });
                  }}
                />
              ),
            },
          ]}
          rows={[...devices]}
        />
      </Grid>
    </Grid>
  );
};

export default DeleteDevice;
