import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { customLocaleText, darkColor, lightColor } from "./utils";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

type CustomTableProps = {
  columns: GridColDef[];
  rows: any[];
  dense?: boolean;
  onRowClick?: (row: any) => void;
  checkbox?: {
    visible: boolean;
    selectedRows: any[];
    setSelectedRows: (rows: any[]) => void;
  };
  hideFooter?: boolean;
};

const CustomTable: React.FC<CustomTableProps> = ({
  columns,
  rows,
  dense,
  onRowClick,
  checkbox,
  hideFooter,
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <DataGrid
      scrollbarSize={0}
      key={`key-table`}
      sx={{
        ".MuiDataGrid-columnHeaderTitle": {
          fontWeight: "bold",
          fontSize: "1.1rem",
          color: darkColor,
        },
        ".MuiDataGrid-columnHeader": {
          backgroundColor: lightColor,
          border: "none",
          minWidth: isMobile ? "250px !important" : undefined,
          maxWidth: isMobile ? "250px !important" : undefined,
        },
        ".MuiDataGrid-row": {
          cursor: onRowClick ? "pointer" : undefined,
        },
        ".MuiDataGrid-overlayWrapper": {
          height: "50px",
        },
        ".MuiDataGrid-cell": {
          minWidth: isMobile ? "250px !important" : undefined,
          maxWidth: isMobile ? "250px !important" : undefined,
        },
      }}
      getRowId={(row) => rows.indexOf(row)}
      density={dense ? "compact" : "standard"}
      rows={rows}
      columns={columns}
      checkboxSelection={checkbox && checkbox.visible ? true : false}
      rowSelection={checkbox && checkbox.visible ? true : false}
      onRowSelectionModelChange={
        checkbox && checkbox.visible
          ? (newSelection) => checkbox.setSelectedRows(newSelection)
          : undefined
      }
      onRowClick={onRowClick ? (params) => onRowClick(params.row) : undefined}
      localeText={customLocaleText(t)}
      isCellEditable={() => false}
      slots={{
        noRowsOverlay: () => (
          <div style={{ textAlign: "center", paddingTop: "12px" }}>
            {t("noData")}
          </div>
        ),
      }}
      hideFooter={hideFooter ? hideFooter : false}
    />
  );
};

export default CustomTable;
