import React, { useState } from "react";
import { darkColor, lightColor } from "./utils";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type CustomButtonProps = {
  label: string;
  type: "outlined" | "contained";
  onClick: (() => void) | ((e: any) => void);
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  fullWidth?: boolean;
  confirm?: boolean;
  confirmQuestion?: string;
  startIcon?: any; //TODO: Fix any
  endIcon?: any; //TODO: Fix any
};

const CustomButton: React.FC<CustomButtonProps> = ({
  label,
  type,
  onClick,
  size,
  disabled,
  fullWidth,
  confirm,
  confirmQuestion,
  startIcon,
  endIcon,
}) => {
  const { t } = useTranslation();

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const handleOpenConfirm = () => setConfirmOpen(true);
  const handleCloseConfirm = () => setConfirmOpen(false);

  return (
    <>
      <Button
        key={`key-${label}`}
        variant={type}
        onClick={confirm ? handleOpenConfirm : onClick}
        size={size ? size : "small"}
        disabled={disabled ? disabled : undefined}
        fullWidth={fullWidth ? fullWidth : undefined}
        startIcon={startIcon ? startIcon : undefined}
        endIcon={endIcon ? endIcon : undefined}
        style={{
          backgroundColor:
            type === "contained" && !disabled ? lightColor : undefined,
          color: !disabled ? darkColor : undefined,
          borderColor: type === "outlined" && !disabled ? darkColor : undefined,
          boxShadow: "none",
        }}
      >
        {label}
      </Button>
      <Dialog open={confirmOpen} onClose={handleCloseConfirm}>
        <DialogContent>
          <DialogContentText>
            {confirmQuestion ? confirmQuestion : t("areYouSure")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton
            label={t("no")}
            type="outlined"
            onClick={handleCloseConfirm}
          />
          <CustomButton
            label={t("yes")}
            type="contained"
            onClick={(e: any) => {
              onClick(e);
              handleCloseConfirm();
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomButton;
