import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { mainUrl, roles } from "../costants";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import CustomTable from "../../custom/CustomTable";
import CustomSelect from "../../custom/CustomSelect";
import CustomLoading from "../../custom/CustomLoading";
import { getDevices, updateDevice } from "../../../api/services/deviceService";
import {
  checkZfsStatus,
  commit,
  getZfs,
  getZfsFile,
  init,
  uploadFiles,
} from "../../../api/services/configuratorService";
import {
  prepareConfigJson,
  prepareNetTestJson,
  prepareParamsJson,
  prepareSensorsJson,
} from "../../custom/utils";
import { useSnackbar } from "notistack";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { getLastData } from "../../../api/services/tsservice";
import CustomFeedback from "../../custom/CustomFeedback";

type CostsAndSettingsProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  role: string;
};

const CostsAndSettings: React.FC<CostsAndSettingsProps> = ({
  setBreadcrumb,
  role,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [values, setValues] = useState({
    device: "",
    kgThreshold1: "",
    price1: "",
    price2: "",
    amount1: "",
    amount2: "",
  });

  const [reload, setReload] = useState<boolean>(false);
  const [loadingDevices, setLoadingDevices] = useState<boolean>(true);
  const [devices, setDevices] = useState<any[]>([]);
  const [tableValues, setTableValues] = useState<any[]>([]);

  const [baseValues, setBaseValues] = useState({
    price1: 0,
    price2: 0,
    amount1: 0,
    amount2: 0,
    thresholds: [0, 0, 0, 0],
    macinatore1: [0, 0, 0],
    macinatore2: [0, 0, 0],
    attraversamento: 0,
    macchina_w_thresh: 0,
    macina1_w_thresh: 0,
    macina2_w_thresh: 0,
  });
  const [net, setNet] = useState<any>({});

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("costsAndSettings")}
      </Typography>,
    ]);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let promises = devices.map((d: any) =>
        getLastData(d.id, "d", "*").then((res) => {
          if (
            res &&
            res.result &&
            res.result.values &&
            res.result.values[0].value &&
            res.result.values[0].value.macina_1 &&
            res.result.values[0].value.macina_2
          ) {
            return {
              id: d.id,
              name: d.name,
              user: d.customer_assigned_name,
              price1: res.result.values[0].value.macina_1.costo,
              price2: d.doppio_macinatore
                ? res.result.values[0].value.macina_2.costo
                : "N/A",
              amount1: res.result.values[0].value.macina_1["g/dose"],
              amount2: d.doppio_macinatore
                ? res.result.values[0].value.macina_2["g/dose"]
                : "N/A",
              kgThreshold1: d?.coffee_max_quantity || "N/A",
            };
          } else {
            return {
              id: d.id,
              name: d.name,
              user: d.customer_assigned_name,
              price1: "N/A",
              price2: "N/A",
              amount1: "N/A",
              amount2: "N/A",
              kgThreshold1: d?.coffee_max_quantity || "N/A",
            };
          }
        })
      );
      let tmpTableValues = await Promise.all(promises);
      setTableValues(tmpTableValues);
    };
    fetchData();
  }, [devices]);

  useEffect(() => {
    const checkLocalStorage = () => {
      const tmp = localStorage.getItem("zerynth_deleting_device");
      if (!tmp) {
        clearInterval(interval); // Pulisce l'interval dopo il primo controllo
        getDevices().then((r: any) => {
          if (r && r.installations) {
            setDevices([...r.installations]);
            let tmp: any[] = [];
            let tmpNet = {};
            r.installations.map((d: any) => {
              if (d && d.id) {
                getZfs(d.id).then((res: any) => {
                  if (res && res.files) {
                    const netFile = res.files.find(
                      (file: any) => file.path === "net.json"
                    );
                    if (netFile) {
                      getZfsFile(d.id, netFile.id).then((resFile) => {
                        tmpNet = resFile;
                      });
                    }
                  } else {
                    tmp = [
                      ...tmp,
                      {
                        id: d.id,
                        name: d.name,
                        user: d.customer_assigned_name,
                        price1: "N/A",
                        price2: "N/A",
                        amount1: "N/A",
                        amount2: "N/A",
                        net: tmpNet,
                      },
                    ];
                  }
                });
              }
            });
          }
          setLoadingDevices(false);
        });
      }
    };
    const interval = setInterval(checkLocalStorage, 1000);
    checkLocalStorage();
    return () => clearInterval(interval);
  }, [reload]);

  useEffect(() => {
    if (values.device) {
      getZfs(values.device).then((res: any) => {
        if (res && res.files) {
          const netFile = res.files.find(
            (file: any) => file.path === "net.json"
          );
          const paramsFile = res.files.find(
            (file: any) => file.path === "params.json"
          );
          if (netFile) {
            getZfsFile(values.device, netFile.id).then((resFile) => {
              setNet(resFile);
            });
          }
          if (paramsFile) {
            getZfsFile(values.device, paramsFile.id).then((resFile: any) => {
              setBaseValues({
                price1: resFile.macina_1.costo,
                price2: resFile.macina_2.costo,
                amount1: resFile.macina_1["g/dose"],
                amount2: resFile.macina_2["g/dose"],
                thresholds: resFile.macchina.th,
                macinatore1: resFile.macina_1.th,
                macinatore2: resFile.macina_2.th,
                attraversamento: resFile.t_attraversamento,
                macchina_w_thresh: resFile.macchina.w_thresh,
                macina1_w_thresh: resFile.macina_1.w_thresh,
                macina2_w_thresh: resFile.macina_2.w_thresh,
              });
            });
          }
        }
      });
    }
  }, [values.device]);

  const handleSubmit = () => {
    if (devices && values.device) {
      const doppio_macinatore = devices.filter((d) => d.id === values.device)[0]
        .doppio_macinatore;
      if (
        (doppio_macinatore &&
          values.amount1 &&
          values.amount1 !== "N/A" &&
          values.amount2 &&
          values.amount2 !== "N/A" &&
          values.price1 &&
          values.price1 !== "N/A" &&
          values.price2 &&
          values.price2 !== "N/A" &&
          values.device) ||
        (!doppio_macinatore &&
          values.amount1 &&
          values.amount1 !== "N/A" &&
          values.price1 &&
          values.price1 !== "N/A" &&
          values.device)
      ) {
        const netFile = {
          file: new File(
            [new TextEncoder().encode(JSON.stringify(net))] as BlobPart[],
            "net.json"
          ),
        };
        const paramsFile = {
          file: new File(
            [
              new TextEncoder().encode(
                JSON.stringify(
                  prepareParamsJson(
                    Number(values.price1),
                    Number(values.price2),
                    Number(values.amount1),
                    Number(values.amount2),
                    baseValues.thresholds,
                    baseValues.macinatore1,
                    baseValues.macinatore2,
                    baseValues.attraversamento,
                    baseValues.macchina_w_thresh,
                    baseValues.macina1_w_thresh,
                    baseValues.macina2_w_thresh
                  )
                )
              ),
            ] as BlobPart[],
            "params.json"
          ),
        };
        const netTestFile = {
          file: new File(
            [
              new TextEncoder().encode(JSON.stringify(prepareNetTestJson)),
            ] as BlobPart[],
            "net_test.json"
          ),
        };
        const sensorsFile = {
          file: new File(
            [
              new TextEncoder().encode(JSON.stringify(prepareSensorsJson)),
            ] as BlobPart[],
            "sensors.json"
          ),
        };
        const configFile = {
          file: new File(
            [
              new TextEncoder().encode(JSON.stringify(prepareConfigJson)),
            ] as BlobPart[],
            "config.json"
          ),
        };
        init(values.device)
          .then(
            async () =>
              await uploadFiles(values.device, [
                netFile,
                paramsFile,
                sensorsFile,
                configFile,
                netTestFile,
              ]).then(async (res: any) => {
                if (res && res.files) {
                  await commit(values.device, true).then((r: any) => {
                    if (r && r.version) {
                      enqueueSnackbar(t("parametersSuccess"), {
                        variant: "success",
                      });
                      setValues({
                        amount1: "",
                        amount2: "",
                        price1: "",
                        price2: "",
                        device: "",
                        kgThreshold1: "",
                      });
                      setBaseValues({
                        amount1: 0,
                        amount2: 0,
                        price1: 0,
                        price2: 0,
                        attraversamento: 0,
                        thresholds: [0, 0, 0, 0],
                        macinatore1: [0, 0, 0],
                        macinatore2: [0, 0, 0],
                        macchina_w_thresh: 0,
                        macina1_w_thresh: 0,
                        macina2_w_thresh: 0,
                      });
                      setReload(!reload);
                    } else {
                      enqueueSnackbar(
                        t("parametersError") + " " + res?.err?.message,
                        {
                          variant: "error",
                        }
                      );
                    }
                  });
                } else {
                  enqueueSnackbar(
                    t("parametersError") + " " + res?.err?.message,
                    {
                      variant: "error",
                    }
                  );
                }
              })
          )
          .catch((err: any) => {
            console.log("ERROR", err);
            enqueueSnackbar(t("parametersError"), {
              variant: "error",
            });
          });
      } else {
        enqueueSnackbar(t("completeAllFields"), {
          variant: "error",
        });
      }
      if (values.kgThreshold1) {
        const tmpDevice = devices.filter((d) => d.id === values.device)[0];
        updateDevice({
          id: values.device,
          id_gestionale: tmpDevice?.id_gestionale || "N/A",
          installation_info: {
            address: tmpDevice?.installation_info?.address || "N/A",
            city: tmpDevice?.installation_info?.city || "N/A",
            province: tmpDevice?.installation_info?.province || "N/A",
          },
          model: tmpDevice?.model || "N/A",
          name: tmpDevice?.name || "N/A",
          phone_operator: tmpDevice?.phone_operator || "N/A",
          sys_num: tmpDevice?.system_number || "N/A",
          sys_serial_num: tmpDevice?.system_serial_number || "N/A",
          azienda_email: tmpDevice?.azienda_email || "N/A",
          cliente_email: tmpDevice?.cliente_email || "N/A",
          coffee_max_quantity: Number(values.kgThreshold1),
        }).then((rr) => {
          if (rr && rr.err) {
            enqueueSnackbar(t("parametersError") + " " + rr?.err?.message, {
              variant: "error",
            });
            setReload(!reload);
          }
        });
      } else {
        enqueueSnackbar(t("completeAllFields"), {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar(t("completeAllFields"), {
        variant: "error",
      });
    }
  };

  const [runningZfs, setRunningZfs] = useState<boolean>(false);
  useEffect(() => {
    const checkStatus = async () => {
      checkZfsStatus(values.device)
        .then((res) => {
          if (res && res.job && res.job.status) {
            if (res.job.status === "pending") {
              setRunningZfs(true);
            } else {
              setRunningZfs(false);
            }
          }
        })
        .catch((err) => {
          console.log("ERROR", err);
          setRunningZfs(false);
        });
    };
    if (values.device) {
      checkStatus();
      const intervalId = setInterval(checkStatus, 30000);
      return () => clearInterval(intervalId);
    }
  }, [values.device, reload]);

  const getColumns = () => {
    let tmpcolumn = [
      {
        headerName: `${t("deviceName")}`,
        field: "name",
        flex: 1,
      },
      {
        headerName: `${t("user")}`,
        field: "user",
        flex: 1,
      },
      {
        headerName: `${t("price1")}`,
        field: "price1",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.price1 === "N/A"
            ? params.row.price1
            : `${params.row.price1} €/kg`;
        },
      },
      {
        headerName: `${t("price2")}`,
        field: "price2",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.price2 === "N/A"
            ? params.row.price2
            : `${params.row.price2} €/kg`;
        },
      },
      {
        headerName: `${t("gAmount1")}`,
        field: "amount1",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.amount1 === "N/A"
            ? params.row.amount1
            : `${params.row.amount1} g. IN`;
        },
      },
      {
        headerName: `${t("gAmount2")}`,
        field: "amount2",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.amount2 === "N/A"
            ? params.row.amount2
            : `${params.row.amount2} g. IN`;
        },
      },
    ];
    if (role === roles.installer || role === roles.roasting) {
      tmpcolumn.push({
        headerName: `${t("groundKgThreshold")} ${t("grinder")} 1`,
        field: "kgThreshold1",
        flex: 1,
      });
    }
    return tmpcolumn;
  };

  if (loadingDevices) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={`${t("costsAndSettings")}`}
          goBack={() => navigate(mainUrl)}
        />
      </Grid>
      {runningZfs && (
        <Grid item xs={12}>
          <CustomFeedback label={t("workInProgress")} type="warning" />
        </Grid>
      )}
      <Grid item xs={12}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={`${t("device")}`}
                  value={values.device}
                  handleChange={(e) => {
                    setValues({ ...values, device: e.target.value });
                    tableValues.map((d: any) => {
                      if (d.id === e.target.value) {
                        setValues({
                          price1: d.price1,
                          price2: d.price2,
                          amount1: d.amount1,
                          amount2: d.amount2,
                          device: d.id,
                          kgThreshold1: d.kgThreshold1,
                        });
                      }
                    });
                  }}
                  options={[
                    ...devices.map((device) => ({
                      label: `${device.name} (${device.customer_assigned_name})`,
                      value: device.id,
                    })),
                  ]}
                />
              </Grid>
              {(role === roles.installer || role === roles.roasting) && (
                <Grid item xs={12} md={6}>
                  <CustomInput
                    label={`${t("groundKgThreshold")}`}
                    value={values.kgThreshold1}
                    handleChange={(e) =>
                      setValues({ ...values, kgThreshold1: e.target.value })
                    }
                    endAdornment="kg"
                    type="number"
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("price1")}
                  value={values.price1}
                  handleChange={(e) =>
                    setValues({ ...values, price1: e.target.value })
                  }
                  endAdornment="€/kg"
                  type="number"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("gAmount1")}
                  value={values.amount1}
                  handleChange={(e) =>
                    setValues({ ...values, amount1: e.target.value })
                  }
                  endAdornment="g. IN"
                  type="number"
                />
              </Grid>
              {devices &&
                devices.filter((d) => d.id === values.device).length > 0 &&
                devices.filter((d) => d.id === values.device)[0]
                  .doppio_macinatore && (
                  <Grid item xs={12} md={6}>
                    <CustomInput
                      label={t("price2")}
                      value={values.price2}
                      handleChange={(e) =>
                        setValues({ ...values, price2: e.target.value })
                      }
                      endAdornment="€/kg"
                      type="number"
                    />
                  </Grid>
                )}
              {devices &&
                devices.filter((d) => d.id === values.device).length > 0 &&
                devices.filter((d) => d.id === values.device)[0]
                  .doppio_macinatore && (
                  <Grid item xs={12} md={6}>
                    <CustomInput
                      label={t("gAmount2")}
                      value={values.amount2}
                      handleChange={(e) =>
                        setValues({ ...values, amount2: e.target.value })
                      }
                      endAdornment="g. IN"
                      type="number"
                    />
                  </Grid>
                )}
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() => {
                setValues({
                  amount1: "",
                  amount2: "",
                  price1: "",
                  price2: "",
                  device: "",
                  kgThreshold1: "",
                });
                setBaseValues({
                  amount1: 0,
                  amount2: 0,
                  price1: 0,
                  price2: 0,
                  attraversamento: 0,
                  thresholds: [0, 0, 0, 0],
                  macinatore1: [0, 0, 0],
                  macinatore2: [0, 0, 0],
                  macchina_w_thresh: 0,
                  macina1_w_thresh: 0,
                  macina2_w_thresh: 0,
                });
              }}
              type="outlined"
              confirm
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
              disabled={runningZfs}
            />,
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTable columns={getColumns()} rows={[...tableValues]} />
      </Grid>
    </Grid>
  );
};

export default CostsAndSettings;
