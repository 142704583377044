import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useNavigate } from "react-router-dom";
import { mainUrl } from "../costants";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import CustomTable from "../../custom/CustomTable";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { getRoastingByAdmin } from "../../../api/services/userService";
import CustomLoading from "../../custom/CustomLoading";
import CustomSelect from "../../custom/CustomSelect";
import CustomList from "../../custom/CustomList";
import CustomCard from "../../custom/CustomCard";
import { darkColor } from "../../custom/utils";

type RoastingListProps = {
  title: string;
  adminFilter: boolean;
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  link: string | ((id: string) => string);
};

const RoastingList: React.FC<RoastingListProps> = ({
  title,
  adminFilter,
  setBreadcrumb,
  link,
}) => {
  document.body.style.backgroundColor = "#ffffff";

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [loading1, setLoading1] = useState<boolean>(true);
  const [roastings, setRoastings] = useState<any[]>([]);
  const [admins, setAdmins] = useState<any[]>([]);
  const [selectedAdmin, setSelectedAdmin] = useState<string>("");

  useEffect(() => {
    if ((adminFilter && selectedAdmin) || !adminFilter) {
      getRoastingByAdmin(selectedAdmin).then((res) => {
        if (res && res.aziende) {
          setRoastings([...res.aziende]);
        } else {
          setRoastings([]);
        }
        setLoading1(false);
      });
    } else {
      setLoading1(false);
    }
  }, [selectedAdmin]);

  if (loading1) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={adminFilter ? t("roastingOverview") : t(title)}
          goBack={() => navigate(mainUrl)}
        />
      </Grid>
      {adminFilter && (
        <Grid item xs={12}>
          <Typography variant="h5" style={{ color: darkColor }}>
            {t("selectRoasting")}
          </Typography>
        </Grid>
      )}
      {adminFilter && (
        <Grid item xs={12}>
          <CustomSelect
            // label={t("admin")}
            value={selectedAdmin}
            handleChange={(e) => setSelectedAdmin(e.target.value)}
            options={admins.map((a) => ({
              label: `${a.name} (${a.email})`,
              value: a.uid,
            }))}
          />
        </Grid>
      )}
      {adminFilter && (
        <Grid item xs={12}>
          <CustomCard
            header={{ visible: true, title: `${t("registry")}` }}
            content={
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomList
                    listElement={[
                      {
                        primaryText: t("email"),
                        action: admins.find((a) => a.uid === selectedAdmin)
                          ?.email,
                      },
                      {
                        primaryText: t("phone"),
                        action: admins.find((a) => a.uid === selectedAdmin)
                          ?.user_info?.phone,
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomList
                    listElement={[
                      {
                        primaryText: t("creationDate"),
                        action: new Date(
                          admins.find((a) => a.uid === selectedAdmin).created_at
                        ).toLocaleString(),
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="h5" style={{ color: darkColor }}>
          {t("selectRoasting")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          onRowClick={(row) =>
            navigate(typeof link === "string" ? link : link(row.uid))
          }
          columns={[
            {
              headerName: `${t("email")}`,
              field: "email",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <Link
                  style={{ color: "black" }}
                  to={typeof link === "string" ? link : link(params.row.uid)}
                >
                  {params.row.email}
                </Link>
              ),
            },
            {
              headerName: `${t("businessName")}`,
              field: "name",
              flex: 1,
            },
            {
              headerName: `${t("address")}`,
              field: "user_info.address",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  {params.row.user_info.address}, {params.row.user_info.city}{" "}
                  {params.row.user_info.province}
                </>
              ),
            },

            {
              headerName: `${t("phone")}`,
              field: "user_info.phone",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <> {params.row.user_info.phone}</>
              ),
            },
            {
              headerName: `${t("zip")}`,
              field: "user_info.zip_code",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <> {params.row.user_info.zip_code}</>
              ),
            },
          ]}
          rows={roastings}
        />
      </Grid>
    </Grid>
  );
};

export default RoastingList;
