import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomCard from "../../custom/CustomCard";
import {
  BarChart,
  Coffee,
  CoffeeMaker,
  Delete,
  DeveloperBoard,
  Download,
  Handyman,
  Notifications,
  Person,
  Summarize,
  Tune,
} from "@mui/icons-material";
import {
  deviceListToDashboardUrl,
  deviceListToDownloadDataUrl,
  roles,
  routes,
} from "../costants";
import CustomButton from "../../custom/CustomButton";
import { darkColor } from "../../custom/utils";

type HomePageProps = {
  role: string;
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const HomePage: React.FC<HomePageProps> = ({ role, setBreadcrumb }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  document.body.style.backgroundColor = "#ffffff";

  useEffect(() => {
    setBreadcrumb([
      <Typography color="#ffffff" key="1">
        {t("home")}
      </Typography>,
    ]);
  }, []);

  const handleRoleIconCards = () => {
    switch (role) {
      case roles.admin:
        return [
          {
            img: (
              <CoffeeMaker
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [
              t("createRoasting"),
              t("updateRoasting"),
              t("dashboardRoasting"),
            ],
            link: [
              routes.createRoasting,
              routes.roastingListToUpdate,
              routes.dashboardRoasting,
            ],
          },
          {
            img: (
              <Tune
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [t("subscriptionManagement")],
            link: [routes.subscriptionsManagement],
          },
          {
            img: (
              <Notifications
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [t("alarmsHistory")],
            link: [routes.alarmsPage],
          },
        ];
      case roles.installer:
        return [
          {
            img: (
              <BarChart
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [t("dashboard")],
            link: [deviceListToDashboardUrl("all")],
          },
          {
            img: (
              <DeveloperBoard
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [t("createDevice"), t("deleteDevice")],
            link: [routes.createDevice, routes.deleteDevice],
          },
          {
            img: (
              <Coffee
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [t("costsAndSettings")],
            link: [routes.costAndSettings],
          },
          {
            img: (
              <Handyman
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [t("updateParameters")],
            link: [routes.updateParams],
          },
        ];
      case roles.customer:
        return [
          {
            img: (
              <BarChart
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [t("dashboard")],
            link: [deviceListToDashboardUrl("all")],
          },
          {
            img: (
              <Download
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [t("dataHistory")],
            link: [deviceListToDownloadDataUrl("all")],
          },
          {
            img: (
              <Notifications
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [t("alarmsHistory")],
            link: [routes.alarmsPage],
          },
          {
            img: (
              <Summarize
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [t("report")],
            link: [routes.reportsClientPage],
          },
          {
            img: (
              <Handyman
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [t("updateParameters")],
            link: [routes.updateParams],
          },
        ];
      case roles.roasting:
        return [
          {
            img: (
              <BarChart
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [t("dashboard")],
            link: [deviceListToDashboardUrl("all")],
          },
          {
            img: (
              <Download
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [t("dataHistory")],
            link: [deviceListToDownloadDataUrl("all")],
          },
          {
            img: (
              <Notifications
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [t("alarmsHistory")],
            link: [routes.alarmsPage],
          },
          {
            img: (
              <Summarize
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [t("report")],
            link: [routes.reportsRoastingPage],
          },
          {
            img: (
              <Person
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [t("createUser"), t("updateUser")],
            link: [routes.createUsers, routes.usersList],
          },
          {
            img: (
              <Coffee
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [t("costsAndSettings")],
            link: [routes.costAndSettings],
          },
          {
            img: (
              <Handyman
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [t("updateParameters")],
            link: [routes.updateParams],
          },
          {
            img: (
              <Delete
                style={{
                  color: darkColor,
                  fontSize: "250px",
                }}
              />
            ),
            text: [t("deleteDevice")],
            link: [routes.deleteDevice],
          },
        ];
      default:
        return [];
    }
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      {handleRoleIconCards().map((iconItem) => (
        <Grid item xs={12} md={6} key={iconItem.text.join("-")}>
          <CustomCard
            content={
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>{iconItem.img}</Grid>
              </Grid>
            }
            actions={iconItem.text.map(
              (buttonText: string, buttonIndex: number) => (
                <CustomButton
                  fullWidth
                  type="contained"
                  label={buttonText}
                  onClick={() => navigate(iconItem.link[buttonIndex])}
                />
              )
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default HomePage;
