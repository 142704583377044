export const idParam = ":id";

export const loginPageUrl = `/login`;
export const resetPasswordPageUrlResult = `/reset/result`;
export const recoverPageUrlResult = `/recover/result`;
export const resetPasswordPageUrl = `/recover`;
export const recoverPageUrl = `/auth/recover/end`;

export const mainUrl = `*`;
export const profileUrl = `/profile`;

//DASHBOARDS
export const roastingListToDashboardUrl = `/data/dashboard/roasting`;
export const deviceListToDashboardUrl = (id: string) =>
  `/data/dashboard/roasting/${id}/devices`;
export const dashboardPageUrl = (id: string) =>
  `/data/dashboard/roasting/devices/${id}`;
export const dashboardRoastingUrl = `/data/dashboard/roasting/all`;
export const dashboardRoastingCustomerUrl = (id: string) =>
  `/data/dashboard/roasting//${id}`;

//DOWNLOAD DATA
export const roastingListToDownloadDataUrl = `/data/download/roasting`;
export const deviceListToDownloadDataUrl = (id: string) =>
  `/data/download/roasting/${id}/devices`;
export const downloadPageUrl = (id: string) =>
  `/data/download/roasting/devices/${id}`;

//ALARMS
export const alarmsPageUrl = `/alarms`;

//REPORTS
export const reportsRoastingPageUrl = `/reports/roasting`;
export const reportsClientPageUrl = `/reports/client`;

//ROASTING MANAGING
export const createRoastingUrl = `/roastings/new`;
export const roastingListToUpdateUrl = `/roastings/update/`;
export const updateRoastingUrl = (id: string) => `/roastings/update/${id}`;

//INSTALLER AND USER MANAGING
export const createUsersUrl = `/users/new`;
export const usersListUrl = `/users/list`;
export const updateUserUrl = (id: string) => `/users/update/${id}`;
export const updateCostsUrl = `/users/costs`;

//DEVICE MANAGING
export const createDeviceUrl = `/devices/new`;
export const devicesListToUpdateUrl = `/devices/update`;
export const updateDeviceUrl = (id: string) => `/devices/update/${id}`;
export const updateDeviceExpirationUrl = (id: string) =>
  `/devices/expiration/${id}`;
export const configurationParamsUrl = (id: string) =>
  `/devices/${id}/configuration/params`;
export const configurationSliderUrl = (id: string) =>
  `/devices/${id}/configuration/slider`;
export const configurationUrl = (id: string) =>
  `/devices/${id}/configuration/fw`;
export const configurationEndUrl = (id: string) =>
  `/devices/${id}/configuration/end`;
export const subscriptionsManagementUrl = `/devices/subscriptions`;
export const updateSubscriptionsUrl = (id: string) =>
  `/devices/subscriptions/${id}`;
export const costAndSettingsUrl = `/devices/settings`;
export const updateParamsUrl = `/devices/user/params`;
export const deleteDeviceUrl = `/devices/delete`;

export const routes = {
  login: loginPageUrl,
  resetResult: resetPasswordPageUrlResult,
  recoverResult: recoverPageUrlResult,
  recoverStart: resetPasswordPageUrl,
  recoverEnd: recoverPageUrl,
  mainPage: mainUrl,
  profile: profileUrl,
  roastingListToDashboard: roastingListToDashboardUrl,
  deviceListToDashboard: deviceListToDashboardUrl(idParam),
  dashboardPage: dashboardPageUrl(idParam),
  dashboardRoasting: dashboardRoastingUrl,
  dashboardRoastingCustomer: dashboardRoastingCustomerUrl(idParam),
  roastingListToDownloadData: roastingListToDownloadDataUrl,
  deviceListToDownloadData: deviceListToDownloadDataUrl(idParam),
  downloadPage: downloadPageUrl(idParam),
  alarmsPage: alarmsPageUrl,
  reportsRoastingPage: reportsRoastingPageUrl,
  reportsClientPage: reportsClientPageUrl,
  createRoasting: createRoastingUrl,
  roastingListToUpdate: roastingListToUpdateUrl,
  updateRoasting: updateRoastingUrl(idParam),
  createUsers: createUsersUrl,
  usersList: usersListUrl,
  updateUser: updateUserUrl(idParam),
  updateCosts: updateCostsUrl,
  createDevice: createDeviceUrl,
  devicesListToUpdate: devicesListToUpdateUrl,
  updateDevice: updateDeviceUrl(idParam),
  updateDeviceExpiration: updateDeviceExpirationUrl(idParam),
  configurationParams: configurationParamsUrl(idParam),
  configurationSlider: configurationSliderUrl(idParam),
  configuration: configurationUrl(idParam),
  configurationEnd: configurationEndUrl(idParam),
  subscriptionsManagement: subscriptionsManagementUrl,
  updateSubscriptions: updateSubscriptionsUrl(idParam),
  costAndSettings: costAndSettingsUrl,
  updateParams: updateParamsUrl,
  deleteDevice: deleteDeviceUrl,
};

export const roles = {
  admin: "admin",
  installer: "installer",
  customer: "customer",
  roasting: "roasting",
};

export const isDev =
  process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test";
