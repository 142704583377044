import React from "react";
import { useNavigate } from "react-router-dom";
import { loginPageUrl } from "../costants";
import logo from "../../../images/logo.png";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomText from "../../custom/CustomText";
import { CheckCircle } from "@mui/icons-material";

type ResultRecoverPasswordProps = {};

const ResultRecoverPassword: React.FC<ResultRecoverPasswordProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  document.body.style.backgroundColor = "#000000";

  const submit = () => navigate(loginPageUrl);

  return (
    <Grid height="100vh" container justifyContent="center" alignItems="center">
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Grid item xs={7}>
          <img
            src={logo}
            alt="logo"
            width="30%"
            style={{ marginLeft: "34%", marginRight: "34%" }}
          />
        </Grid>
        <Grid item xs={11} md={8}>
          <CustomCard
            content={
              <Grid
                item
                container
                flexDirection="column"
                spacing={2}
                alignItems="center"
              >
                <Grid item>
                  <CheckCircle style={{ color: "green", fontSize: "150px" }} />
                </Grid>
                <Grid item>
                  <CustomText label={t("changePasswordTitle")} type="h4" />
                </Grid>
                <Grid item>
                  <CustomText
                    label={t("changePasswordSubTitle")}
                    type="subtitle1"
                  />
                </Grid>
                <Grid item xs={12} width="100%">
                  <CustomButton
                    fullWidth
                    type="contained"
                    label={t("login")}
                    onClick={submit}
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResultRecoverPassword;
