import React from "react";
import logo from "../../../images/logo.png";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomCard from "../../custom/CustomCard";
import { CheckCircle } from "@mui/icons-material";
import CustomText from "../../custom/CustomText";
import CustomButton from "../../custom/CustomButton";
import { loginPageUrl } from "../costants";
import { useNavigate } from "react-router-dom";

type ResultResetPasswordProps = {};

const ResultResetPassword: React.FC<ResultResetPasswordProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  document.body.style.backgroundColor = "#000000";

  return (
    <Grid height="100vh" container justifyContent="center" alignItems="center">
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Grid item xs={7}>
          <img
            src={logo}
            alt="logo"
            width="30%"
            style={{ marginLeft: "34%", marginRight: "34%" }}
          />
        </Grid>
        <Grid item xs={11} md={8}>
          <CustomCard
            content={
              <Grid
                item
                container
                flexDirection="column"
                spacing={2}
                alignItems="center"
              >
                <Grid item>
                  <CheckCircle style={{ color: "green", fontSize: "150px" }} />
                </Grid>
                <Grid item>
                  <CustomText label={t("resetPasswordResultTitle")} type="h4" />
                </Grid>
                <Grid item>
                  <CustomText
                    label={t("resetPasswordResultSubTitle")}
                    type="subtitle1"
                  />
                </Grid>
              </Grid>
            }
            actions={[
              <CustomButton
                fullWidth
                type="contained"
                label={t("login")}
                onClick={() => navigate(loginPageUrl)}
              />,
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResultResetPassword;
