import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import { configurationParamsUrl, mainUrl } from "../costants";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { useSnackbar } from "notistack";
import CustomSelect from "../../custom/CustomSelect";
import { createDevice } from "../../../api/services/deviceService";
import { getUsers } from "../../../api/services/userService";
import CustomLoading from "../../custom/CustomLoading";
import CustomText from "../../custom/CustomText";

type CreateDeviceProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const CreateDevice: React.FC<CreateDeviceProps> = ({ setBreadcrumb }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("createDevice")}
      </Typography>,
    ]);
  }, []);

  const [users, setUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    getUsers("customers").then((res) => {
      if (res && res.customers) {
        setUsers(res.customers);
      }
      setLoading(false);
    });
  }, []);

  const [values, setValues] = useState({
    doppio_macinatore: false,
    user: "",
    name: "",
    address: "",
    city: "",
    province: "",
    fw: "retrofit",
    machine: "",
    grinder: "",
    coffeeMaxGrinder: 0,
  });

  const handleSubmit = () => {
    createDevice({
      customer_id: values.user,
      customer_destination_id: values.user,
      name: values.name,
      sys_num: values.name,
      sys_serial_num: values.name,
      model: values.fw,
      installation_info: {
        address: values.address,
        city: values.city,
        province: values.province,
      },
      phone_operator: "test",
      installations_container_id: "",
      machine_brand: values.grinder,
      machine_model: values.machine,
      doppio_macinatore: values.doppio_macinatore,
      coffee_max_quantity: 0,
    }).then((res: any) => {
      if (res && res.installation && res.installation.id) {
        enqueueSnackbar(t("createDeviceSuccess"), { variant: "success" });
        navigate(configurationParamsUrl(res.installation.id));
      } else {
        enqueueSnackbar(`${t("createDeviceError")} ${res?.err?.message}`, {
          variant: "error",
        });
      }
    });
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("createDevice")}
          goBack={() => navigate(mainUrl)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={`${t("customer")}`}
                  value={values.user}
                  handleChange={(e: any) =>
                    setValues({ ...values, user: e.target.value })
                  }
                  options={[
                    ...users.map((user) => ({
                      label: `${user.name} (${user.email})`,
                      value: user.uid,
                    })),
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("coffeeMaxGrinder")}
                  value={values.coffeeMaxGrinder}
                  type="number"
                  handleChange={(e) =>
                    setValues({
                      ...values,
                      coffeeMaxGrinder: Number(e.target.value),
                    })
                  }
                  endAdornment="Kg"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("name")}
                  value={values.name}
                  handleChange={(e) =>
                    setValues({ ...values, name: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("address")}
                  value={values.address}
                  handleChange={(e) =>
                    setValues({ ...values, address: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("city")}
                  value={values.city}
                  handleChange={(e) =>
                    setValues({ ...values, city: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("province")}
                  value={values.province}
                  handleChange={(e) =>
                    setValues({ ...values, province: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("machineModel")}
                  value={values.machine}
                  handleChange={(e) =>
                    setValues({ ...values, machine: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("grinderModel")}
                  value={values.grinder}
                  handleChange={(e) =>
                    setValues({ ...values, grinder: e.target.value })
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <CustomText label={`${t("doubleGrinder")}`} type="h6" />
                </Grid>
                <Grid item>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={values.doppio_macinatore}
                    onChange={() =>
                      setValues({
                        ...values,
                        doppio_macinatore: !values.doppio_macinatore,
                      })
                    }
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="1"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="2"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() =>
                setValues({
                  name: "",
                  user: "",
                  address: "",
                  city: "",
                  province: "",
                  fw: "retrofit",
                  machine: "",
                  grinder: "",
                  doppio_macinatore: false,
                  coffeeMaxGrinder: 0,
                })
              }
              confirm
              type="outlined"
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default CreateDevice;
