import { isDev } from "../../components/pages/costants";
import { GetExportInfoRequest } from "../requests/deviceService";

// BASE URLS
export const backendUrl = window._env_.API_ENDPOINT!;
export const grafanaUrl = window._env_.GRAFANA_ENDPOINT!;
export const zdmUrl = isDev
  ? "https://app.test.idealcup.localhost/proxy"
  : `https://app.idealcup.zerynth.com/proxy`;

//LOGIN SERVICE
export const loginUrl = `${backendUrl}/auth/login`;
export const logoutUrl = `${backendUrl}/auth/logout`;
export const setNewPasswordUrl = `${backendUrl}/auth/recover/end`;
export const resetPasswordUrl = `${backendUrl}/auth/recover`;

//USERS SERVICE
export const getUsersUrl = (type: "customers" | "installers" | "roastings") =>
  `${backendUrl}/users/${type}`;
export const createUserUrl = `${backendUrl}/users`;
export const getProfileUrl = `${backendUrl}/auth/profile`;
export const getUserUrl = (uid: string) => `${backendUrl}/users/${uid}`;
export const updateUserUrl = (uid: string) => `${backendUrl}/users/${uid}`;
export const getRoastingByAdminUrl = (uid: string) =>
  `${backendUrl}/users/aziende?admin=${uid}`;
export const deleteUserUrl = (uid: string) => `${backendUrl}/users/${uid}`;

// CONFGURATOR SERVICE
export const createNewDeviceUrl = (workspaceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices`;
export const getIdentityFromDCNUrl = (dcn: string) =>
  `${zdmUrl}/identities/${dcn}/devices`;
export const claimDeviceUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/identities`;
export const activeSimUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/sims`;
export const mklfsUrl = `${zdmUrl}/mklfs/`; // Non rimuovere lo / finale perché senno smette di funzionare
export const initUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/zfs/init`;
export const uploadFilesUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/zfs/upload`;
export const commitUrl = (
  workspaceId: string,
  deviceId: string,
  schedule: boolean
) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/zfs/commit?auto_schedule=${
    schedule ? "true" : "false"
  }`;
export const getFirmwareVersionsUrl = (
  workspaceId: string,
  firmwareId: string
) => `${zdmUrl}/workspaces/${workspaceId}/firmwares/${firmwareId}/versions`;
export const getCloudDeviceUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}`;
export const getZfsUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/zfs/files?zone=R`;
export const getZfsFileUrl = (
  workspaceId: string,
  deviceId: string,
  zfsId: string
) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/zfs/download/${zfsId}`;
export const sendAlarmToRoastingUrl = (email: string) =>
  `${backendUrl}/installations/notifyUpdateIdealCup/${email}`;
export const checkZfsStatusUrl = (deviceId: string) =>
  `${zdmUrl}/devices/${deviceId}/jobs/zfs`;

// DEVICE SERVICE
export const createDeviceUrl = `${backendUrl}/installations`;
export const getDevicesUrl = `${backendUrl}/installations`;
export const getRoastingDevicesUrl = (id: string) =>
  `${backendUrl}/installations/azienda/${id}`;
export const getDeviceUrl = (id: string) => `${backendUrl}/installations/${id}`;
export const deleteDeviceUrl = `${backendUrl}/installations`;
export const getBoardTypeUrl = `${backendUrl}/users/getBoardType`;
export const getContainersUrl = `${backendUrl}/installations/installationsContainers`;
export const sendJobUrl = (id: string, jobName: string) =>
  `${zdmUrl}/devices/${id}/jobs/${jobName}`;
export const getAlarmsUrl = (start: string, end: string) =>
  `${backendUrl}/timeseries/alarms/installations?start=${start}&end=${end}&sort=-start`;
export const getClientReportsUrl = (start: string, end: string) =>
  `${backendUrl}/timeseries/report/customer?start_ts=${start}&end_ts=${end}&sort=-start`;
export const getRoastingReportsUrl = (start: string, end: string) =>
  `${backendUrl}/timeseries/report/roasting?start_ts=${start}&end_ts=${end}&sort=-start`;
export const getDeviceAlarmsUrl = (id: string, end: boolean) => {
  let tmp = `${backendUrl}/timeseries/alarms/installations?installation_id=${id}&sort=-start`;
  if (end) {
    tmp = tmp + "&end=noend";
  }
  return tmp;
};
export const updateAlarmsCalibrationUrl = (id: string) =>
  `${backendUrl}/installations/${id}/alarms`;
export const getAlarmsCalibrationUrl = (id: string) =>
  `${backendUrl}/installations/${id}/alarms`;
export const getExportInfoUrl = (req: GetExportInfoRequest) => {
  const baseUrl = new URL(`${backendUrl}/timeseries/data/exports`);
  baseUrl.searchParams.append("start", req.start);
  baseUrl.searchParams.append("end", req.end);
  baseUrl.searchParams.append("entity_id", req.entity_id);
  baseUrl.searchParams.append("tag", req.tag);
  return baseUrl.toString();
};
export const updateDeviceUrl = (id: string) =>
  `${backendUrl}/installations/${id}`;

// SUBSCRIPTION SERVICE
export const updateSubscriptionStatusUrl = (id: string) =>
  `${backendUrl}/subscriptions/${id}/status`;
export const updateSubscriptionExpirationUrl = (id: string) =>
  `${backendUrl}/subscriptions/${id}/exp`;

export const getLastDataUrl = (id: string, tag: string, variable: string) => {
  if (variable !== "") {
    return `${backendUrl}/timeseries/data/${id}/${tag}.${variable}?sort=DESC&last=true`;
  }
  return `${backendUrl}/timeseries/data/${id}/${tag}?sort=DESC&last=true`;
}