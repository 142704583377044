import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import CustomInput from "../../custom/CustomInput";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import CustomButton from "../../custom/CustomButton";
import { getDevices, updateDevice } from "../../../api/services/deviceService";
import { roles } from "../costants";
import { useSnackbar } from "notistack";

type UpdateNotificationProps = {
  role: string;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const UpdateNotification: React.FC<UpdateNotificationProps> = ({
  role,
  open,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [devices, setDevices] = useState<any[]>([]);
  useEffect(() => {
    getDevices().then((res) => {
      if (res && res.installations) {
        setDevices(res.installations);
        if (res.installations.length > 0) {
          if (role === roles.customer) {
            setValues({ ...values, email: res.installations[0].cliente_email });
          }
          if (role === roles.roasting) {
            setValues({ ...values, email: res.installations[0].azienda_email });
          }
        }
      }
    });
  }, []);

  const [values, setValues] = useState({
    email: "",
  });

  const handleSubmit = () => {
    const body = {
      ...devices[0],
    };
    if (role === roles.customer) {
      body.cliente_email = values.email;
    }
    if (role === roles.roasting) {
      body.azienda_email = values.email;
    }
    updateDevice(body).then((res) => {
      if (res && !res.err) {
        enqueueSnackbar(t("updateEmailSuccess"), { variant: "success" });
        onConfirm();
      } else {
        enqueueSnackbar(`${t("updateEmailError")} ${res?.err?.message}`, {
          variant: "error",
        });
      }
    });
  };

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="md" fullWidth>
      <DialogTitle>{t("notificationManagement")}</DialogTitle>
      <DialogContent style={{ paddingTop: "16px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomInput
              label={t("contactEmailNotification")}
              value={values.email}
              handleChange={(e: any) =>
                setValues({ ...values, email: e.target.value })
              }
              validator={{
                error: values.email
                  ? !values.email.includes("@") || !values.email.includes(".")
                  : false,
                helperText: `${t("invalidEmail")}`,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CustomButton type="outlined" label={t("reset")} onClick={onCancel} />
        <CustomButton type="contained" label={t("ok")} onClick={handleSubmit} />
      </DialogActions>
    </Dialog>
  );
};

export default UpdateNotification;
